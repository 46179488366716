import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appFileUpload]',
})
export class FileUploadDirective implements OnInit, OnDestroy {
  @Output() private uploadedFiles: EventEmitter<Array<File>>;

  private subscription: Subscription;

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.subscription = new Subscription();
    this.uploadedFiles = new EventEmitter<Array<File>>();
  }

  public ngOnInit(): void {
    this.showNativeUploaderOnHostClick();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private showNativeUploaderOnHostClick(): void {
    this.subscription = fromEvent(this.elementRef.nativeElement, 'click').subscribe(() => {
      this.createAndOpenNativeUploader();
    });
  }

  private createAndOpenNativeUploader(): void {
    const input: HTMLInputElement = this.renderer.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    input.setAttribute('multiple', 'true');
    input.setAttribute('name', 'files');
    input.onchange = this.emitFilesOnChange.bind(this);
    input.click();
  }

  private emitFilesOnChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = target.files;

    if (fileList) {
      const files: Array<File> = Array.from(fileList);
      this.uploadedFiles.emit(files);
    }

    target.value = '';
  }
}
