import { Location } from '@angular/common';

import { LocationGetStateModel } from '../../models/location-get-state/location-get-state.model';

export class LocationGetStateUtil {
  public static getState<T>(state: unknown): T | undefined {
    const locationStateData: LocationGetStateModel<T> = state as LocationGetStateModel<T>;

    return locationStateData.locationState;
  }

  public static createState<T>(locationState: T): LocationGetStateModel<T> {
    return { locationState };
  }

  public static clearState(location: Location): void {
    location.replaceState(location.path(), undefined, null);
  }
}
