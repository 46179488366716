import { SettingsDto } from '../../dtos/settings/settings.dto';
import { SettingsModel } from '../../models/settings/settings.model';
import { SettingsOptionListItemModel } from '../../models/settings-option-list-item/settings-option-list-item.model';
import { SettingsOptionListItemDto } from '../../dtos/settings-option-list-item/settings-option-list-item.dto';

export class SettingsCreator {
  public static create(dto: SettingsDto): SettingsModel {
    const model: SettingsModel = {
      key: dto.key,
      type: dto.type,
      value: dto.value,
    };

    return dto.collection ? { ...model, collection: SettingsCreator.createSettingsCollectionValue(dto.collection) } : model;
  }

  private static createSettingsCollectionValue(settingsOptionDto: Array<SettingsOptionListItemDto>): Array<SettingsOptionListItemModel> {
    return settingsOptionDto.map((item: SettingsOptionListItemDto) => {
      return {
        key: item.key.toString(),
        value: item.value,
      };
    });
  }
}
