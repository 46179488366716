import { Component, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss'],
})
export class SupportButtonComponent {
  @Input() public isButtonTextVisible: boolean;

  public supportUrl: string;

  constructor() {
    this.isButtonTextVisible = false;
    this.supportUrl = environment.supportUrl;
  }
}
