import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { DownloadFileDtoCreator } from '../../../shared/creators/download-file-dto/download-file-dto.creator';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DeviceCommunicationSoftwareSettingsDto } from '../../dtos/device-communication-software-settings/device-communication-software-settings.dto';
import { SettingsEditFormDto } from '../../dtos/settings-edit-form/settings-edit-form.dto';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsDto } from '../../dtos/settings/settings.dto';
import { SoftwareInformationSettingsDto } from '../../dtos/software-information-settings/software-information-settings.dto';
import { SettingsGroupEnum } from '../../enums/settings-group/settings-group.enum';
import { SettingsSubGroupEnum } from '../../enums/settings-sub-group/settings-sub-group.enum';
import { SettingsTabsDto } from '../../dtos/settings-tabs/settings-tabs.dto';

@Injectable({
  providedIn: 'root',
})
export class SettingsHttpService {
  constructor(private httpClient: HttpClient) {}

  public getSettings(): Observable<Array<SettingsDto>> {
    return this.httpClient
      .get<ApiResponseDto<Array<SettingsDto>>>(ApiUrlUtil.getApiUrl('setting/settings'))
      .pipe(map((response: ApiResponseDto<Array<SettingsDto>>) => response.data));
  }

  public getGroupSettings(group: SettingsGroupEnum, subGroup?: SettingsSubGroupEnum): Observable<SettingsGroupDto> {
    return this.httpClient
      .get<ApiResponseDto<Array<SettingsGroupDto>>>(ApiUrlUtil.getApiUrl(`setting/settings/${group}`), {
        params: {
          ...(subGroup && { sub_group: subGroup }),
        },
      })
      .pipe(map((response: ApiResponseDto<Array<SettingsGroupDto>>) => response.data[0]));
  }

  public getDeviceCommunicationSettings(): Observable<DeviceCommunicationSoftwareSettingsDto> {
    return this.httpClient
      .get<ApiResponseDto<DeviceCommunicationSoftwareSettingsDto>>(ApiUrlUtil.getApiUrl('setting/settings/custom/device-communication'))
      .pipe(map((response: ApiResponseDto<DeviceCommunicationSoftwareSettingsDto>) => response.data));
  }

  public getSoftwareInformationSettings(): Observable<SoftwareInformationSettingsDto> {
    return this.httpClient
      .get<ApiResponseDto<SoftwareInformationSettingsDto>>(ApiUrlUtil.getApiUrl('setting/settings/custom/software'))
      .pipe(map((response: ApiResponseDto<SoftwareInformationSettingsDto>) => response.data));
  }

  public getSettingsFile(settingsKey: string): Observable<DownloadFileDto> {
    return this.httpClient
      .get<Blob>(ApiUrlUtil.getApiUrl(`setting/settings/${settingsKey}/download`), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(map((response: HttpResponse<Blob>) => DownloadFileDtoCreator.create(response)));
  }

  public editSettings(groupId: string, settings: SettingsEditFormDto): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl(`setting/settings/${groupId}`), {
      ...settings,
    });
  }

  public getSettingsTabs(): Observable<SettingsTabsDto> {
    return this.httpClient
      .get<ApiResponseDto<SettingsTabsDto>>(ApiUrlUtil.getApiUrl('setting/tabs'))
      .pipe(map((response: ApiResponseDto<SettingsTabsDto>) => response.data));
  }

  public exportProductPrices(): Observable<DownloadFileDto> {
    return this.httpClient
      .get<Blob>(ApiUrlUtil.getApiUrl('price-list/price-list/export'), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(map((response: HttpResponse<Blob>) => DownloadFileDtoCreator.create(response)));
  }
}
