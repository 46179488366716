<app-table-row-cell *ngIf="hasCheckbox">
  <div class="form-control-checkbox form-control" [ngClass]="{ 'is-checked': checkboxForm.value }">
    <label class="checkbox">
      <input class="input" type="checkbox" [formControl]="checkboxForm" />
    </label>
  </div>
</app-table-row-cell>

<ng-content select="app-table-row-cell,ng-container"></ng-content>

<ng-container *ngIf="canToggle">
  <app-table-row-cell class="cell-toggle">
    <span class="icon icon-chevron" [class.is-open]="isOpen"></span>
  </app-table-row-cell>

  <ng-container *ngIf="isOpen && detailsTemplate" [ngTemplateOutlet]="detailsTemplate"></ng-container>
</ng-container>
