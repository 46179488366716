import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsHelper } from '../../helpers/settings.helper';
import { PrinterSettingsKeys } from '../../models/printer-settings-keys/printer-settings-keys.model';
import { PrinterSettingsModel } from '../../models/printer-settings/printer-settings.model';
import { SettingsGroupModel } from '../../models/settings-group/settings-group.model';
import { SettingOptionCollectionModel } from '../../models/settings-option-collection/settings-option-collection.model';
import { SettingOptionSingleModel } from '../../models/settings-option-single/settings-option-singe.model';
import { SettingsGroupCreator } from '../settings-group/settings-group.creator';

export class PrinterSettingsCreator {
  public static create(settingsGroupDto: SettingsGroupDto): PrinterSettingsModel {
    const settingsGroup: SettingsGroupModel = SettingsGroupCreator.create(settingsGroupDto);
    const settingsHelper: SettingsHelper<PrinterSettingsKeys> = new SettingsHelper<PrinterSettingsKeys>(settingsGroup.settings);

    const isEnabledOption: SettingOptionSingleModel | null = settingsHelper.getSettingOption<SettingOptionSingleModel>('enabled');
    const selectedPrinterNameOption: SettingOptionCollectionModel | null =
      settingsHelper.getSettingOption<SettingOptionCollectionModel>('printer_name');

    return {
      id: settingsGroup.id,
      name: settingsGroup.name,
      isEnabled: isEnabledOption?.value === 'true',
      printerName: selectedPrinterNameOption?.value ?? '',
    };
  }
}
