import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-thumbnail',
  templateUrl: './color-thumbnail.component.html',
  styleUrls: ['./color-thumbnail.component.scss'],
})
export class ColorThumbnailComponent implements OnInit {
  @Input() public color: string | null;
  @Input() public isSupplementary: boolean;

  public showNoColorIcon: boolean;

  constructor() {
    this.color = null;
    this.showNoColorIcon = false;
    this.isSupplementary = false;
  }

  public ngOnInit(): void {
    if (this.isSupplementary) {
      this.color = '#FFFFFF';
      return;
    }

    this.showNoColorIcon = !this.color;
    if (!this.color) {
      this.color = '#F1F1F1';
    }
  }
}
