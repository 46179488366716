<div class="alert info">
  <span class="alert-icon"></span>

  <span class="alert-text">
    <span>{{ 'shared.selectedRepairAlert.alert' | translate }}</span>

    <span class="bold">{{ repair.number }} ({{ repair.brand }})</span>
  </span>

  <button class="button-link" *ngIf="showCancelButton && !isBpFlowEnabled" (click)="onCancelClick()">
    {{ 'shared.selectedRepairAlert.button' | translate }}
  </button>
</div>
