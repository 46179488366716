import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() public set sizeInPixels(size: number) {
    this.size = `${size}px`;
  }

  public size: string;
  public readonly options: AnimationOptions;

  constructor() {
    this.size = '100px';
    this.options = {
      path: '/assets/lottie/loader.json',
    };
  }
}
