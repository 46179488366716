import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ingredient-tooltips',
  templateUrl: './ingredient-tooltips.component.html',
  styleUrls: ['./ingredient-tooltips.component.scss'],
})
export class IngredientTooltipsComponent {
  @Input() public isWithdraw: boolean;
  @Input() public isPowder: boolean;
  @Input() public isSpecialIngredient: boolean;
  @Input() public isHardener: boolean;
  @Input() public isThinner: boolean;
  @Input() public hasWarning: boolean;

  constructor() {
    this.isWithdraw = false;
    this.isPowder = false;
    this.isSpecialIngredient = false;
    this.isHardener = false;
    this.isThinner = false;
    this.hasWarning = false;
  }
}
