import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-with-checkbox-modal',
  templateUrl: './popup-with-checkbox-modal.component.html',
  styleUrls: ['./popup-with-checkbox-modal.component.scss'],
})
export class PopupWithCheckboxModalComponent {
  @Input() public buttonLabelKey: string;
  @Input() public titleKey: string;
  @Input() public textKey: string;
  @Input() public checkboxLabelKey: string;

  public isCheckboxChecked: boolean;

  constructor(private activeModal: NgbActiveModal) {
    this.buttonLabelKey = '';
    this.titleKey = '';
    this.textKey = '';
    this.checkboxLabelKey = '';
    this.isCheckboxChecked = false;
  }

  public buttonClick(): void {
    this.activeModal.close(this.isCheckboxChecked);
  }
}
