import { DownloadFileDto } from '../../dtos/download-file/download-file.dto';
import { DownloadFileModel } from '../../models/download-file/download-file.model';

export class DownloadFileCreator {
  public static create(downloadFileDto: DownloadFileDto): DownloadFileModel {
    return {
      fileBlob: downloadFileDto.body,
      fileName: downloadFileDto.fileName,
    };
  }
}
