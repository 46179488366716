import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsGroupModel } from '../../models/settings-group/settings-group.model';

export class SettingsGroupCreator {
  public static create(dto: SettingsGroupDto): SettingsGroupModel {
    return {
      id: dto.id,
      name: dto.name,
      settings: dto.settings,
    };
  }
}
