<div ngbDropdown class="dropdown-toggle" placement="top-right">
  <button class="button black-outline" type="button" id="button-dropdown" ngbDropdownToggle>
    <span>{{ 'shared.additionalActionsButton.dropdown.title' | translate }}</span>

    <span class="icon arrow-triangle-down-gray-icon"></span>
  </button>

  <div class="dropdown" ngbDropdownMenu aria-labelledby="button-dropdown">
    <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
  </div>
</div>
