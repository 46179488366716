import { SettingsOptionEditFormDto } from '../../dtos/settings-option-edit-form/settings-option-edit-form.dto';

export class SettingsOptionEditFormDtoCreator {
  public static create<KeyType extends string>(key: KeyType, value: string): SettingsOptionEditFormDto {
    return {
      key,
      value,
    };
  }
}
