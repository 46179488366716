<ul class="errors-list">
  <li *ngFor="let error of errors | keyvalue">
    <ng-container [ngSwitch]="error.key">
      <ng-container *ngSwitchCase="'apiError'">
        <span class="error-message-text">{{ error.value }}</span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span class="error-message-text">{{ 'forms.formControlValidationErrors.' + error.key | translate }}</span>
      </ng-container>
    </ng-container>
  </li>
</ul>
