<ul class="tabs">
  <ng-container *ngFor="let tab of tabs">
    <li
      class="tab"
      *ngIf="!tab?.items?.length; else isDropdown"
      [routerLinkActive]="'active'"
      [class.disabled]="tab.disabled"
      (click)="tabButtonClick(tab)"
    >
      <button class="button tab-button gray-link" type="button" [routerLink]="tab.route" [disabled]="tab.disabled">
        <span class="label">{{ tab.label | translate }}</span>
      </button>
    </li>

    <ng-template #isDropdown>
      <li class="tab dropdown-toggle" ngbDropdown placement="bottom-end">
        <button class="button tab-button gray-link" type="menu" [class.disabled]="tab.disabled" ngbDropdownToggle>
          <span class="label">
            <span>{{ tab.label | translate }}</span>

            <span class="icon arrow-triangle-down-gray-icon"></span>
          </span>
        </button>

        <div class="dropdown" ngbDropdownMenu>
          <button *ngFor="let item of tab.items" class="button gray" type="button" [disabled]="item.disabled">
            <span class="button-label" [routerLink]="item.route" (click)="tabButtonClick(item)">{{ item.label | translate }}</span>
          </button>
        </div>
      </li>
    </ng-template>
  </ng-container>
</ul>
