import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderModalComponent {
  @Input() public loadingMsg: string;
  @Input() public loadingHeader: string;

  constructor() {
    this.loadingMsg = '';
    this.loadingHeader = '';
  }
}
