<div
  class="form-control-textarea form-control"
  [ngClass]="{ 'is-label': isLabel, 'is-focus': isFocus, 'is-error': canShowErrors && control?.invalid }"
>
  <label class="label" [class.is-required]="isRequired$ | async">{{ label | translate }}</label>

  <div class="textarea">
    <textarea
      #textarea
      [placeholder]="placeholder | translate"
      [disabled]="isDisabled"
      [maxlength]="maxlength"
      [ngModel]="control?.value"
      (ngModelChange)="textareaChange($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
    ></textarea>
  </div>

  <app-form-control-validation-errors
    class="errors-list"
    *ngIf="control?.errors as errors"
    [errors]="errors"
  ></app-form-control-validation-errors>
</div>
