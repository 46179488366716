import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { combineLatest, delay, filter, interval, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

import { TableRowComponent } from '../table-row/table-row.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { NoResultsOptionsModel } from '../../models/no-results/no-results-options.model';
import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends SubscriptionStoreComponent implements AfterContentChecked, AfterContentInit, OnDestroy, AfterViewInit {
  @Input() public showNoResults: boolean;
  @Input() public showLoader: boolean;
  @Input() public isScrollEnabled: boolean;
  @Input() public isSelection: boolean;
  @Input() public noResultsOptions?: NoResultsOptionsModel;
  @Input() public scrollHint: string | null;

  @Output() public selectionIndexes: EventEmitter<Array<number>>;

  @ViewChild('table', { static: true }) public tableElement!: ElementRef<HTMLElement>;
  @ViewChild('tableHeader', { static: true }) public tableHeaderElement!: ElementRef<HTMLElement>;
  @ViewChild('tableContent', { static: false }) public tableContentElement!: ElementRef<HTMLElement>;
  @ViewChild(NgScrollbar, { static: false }) public scrollBar!: NgScrollbar;

  @ContentChildren(TableHeaderComponent) public tableHeaders?: QueryList<TableHeaderComponent>;
  @ContentChildren(TableRowComponent) public tableRows?: QueryList<TableRowComponent>;

  public canToggle: boolean;
  public isVerticallyScrollable: boolean;
  public isVerticalScrollUsed: boolean;

  private tableResizeObserver!: ResizeObserver;
  private tableSizeInterval: number;
  private tableSizeIntervalSubscription: Subscription | null;

  constructor(private ngZone: NgZone) {
    super();

    this.selectionIndexes = new EventEmitter<Array<number>>();
    this.canToggle = false;
    this.showLoader = false;
    this.isSelection = false;
    this.showNoResults = false;
    this.isScrollEnabled = true;
    this.tableSizeInterval = 50;
    this.tableSizeIntervalSubscription = null;
    this.isVerticallyScrollable = false;
    this.isVerticalScrollUsed = false;
    this.scrollHint = null;
  }

  public override ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();

    if (this.tableResizeObserver) {
      this.tableResizeObserver.unobserve(this.tableElement.nativeElement);
    }

    if (this.tableSizeIntervalSubscription) {
      this.tableSizeIntervalSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    this.initScrollBarScrollListener();
  }

  public scrollToTop(duration: number = 10): void {
    setTimeout(() => {
      this.scrollBar.scrollTo({ top: 0, start: 0, duration });
    });
  }

  public scrollToBottom(duration: number = 10): void {
    setTimeout(() => {
      this.scrollBar.scrollTo({ bottom: 0, start: 0, duration });
    });
  }

  public ngAfterContentChecked(): void {
    this.initCanToggleStateForHeader();
  }

  public ngAfterContentInit(): void {
    this.initTableSelection();
    this.initTableResizeObserver();
    this.initTableSize();
  }

  public scrollUpdated(ngScrollbar: NgScrollbar): void {
    setTimeout(() => {
      this.isVerticallyScrollable = ngScrollbar.state.isVerticallyScrollable ?? false;
    });
  }

  private initTableSelection(): void {
    if (this.isSelection && this.tableHeaders && this.tableRows) {
      this.setTableSelection(this.tableHeaders, this.tableRows);
    }
  }

  private setTableSelection(headers: QueryList<TableHeaderComponent>, rows: QueryList<TableRowComponent>): void {
    const destroy$: Subject<void> = new Subject();
    const headerCheckbox: FormControl = headers.first.checkboxForm;
    headers.first.hasCheckbox = true;

    this.subscription = rows.changes.pipe(delay(0)).subscribe((rowsLoaded: QueryList<TableRowComponent>) => {
      destroy$.next();

      rowsLoaded.forEach((row: TableRowComponent) => {
        row.hasCheckbox = true;
      });
      const rowsCheckboxes: Array<FormControl> = rowsLoaded.map((row: TableRowComponent) => row.checkboxForm);
      const rowsCheckboxesValue$: Observable<Array<boolean>> = combineLatest(
        rowsLoaded.map((row: TableRowComponent) => row.checkboxValue$)
      );

      this.setHeaderCheckboxStateOnRowCheckboxChange(headerCheckbox, rowsCheckboxesValue$, destroy$);
      this.setRowCheckboxStateOnHeaderCheckboxChange(headerCheckbox, rowsCheckboxes, destroy$);
    });
  }

  private setHeaderCheckboxStateOnRowCheckboxChange(
    headerCheckbox: FormControl,
    rowsCheckboxesValueChanges$: Observable<Array<boolean>>,
    refresh$: Observable<void>
  ): void {
    rowsCheckboxesValueChanges$.pipe(takeUntil(refresh$)).subscribe((rowsChecked: Array<boolean>) => {
      const isEveryChecked: boolean = rowsChecked.every((isSelected: boolean) => isSelected);
      const isSomeChecked: boolean = !isEveryChecked && rowsChecked.some((isSelected: boolean) => isSelected);
      const checkboxState: boolean | undefined = isSomeChecked ? undefined : isEveryChecked;

      headerCheckbox.setValue(checkboxState, { emitEvent: false });

      // @ts-ignore
      const onlyCheckedRowsIdx: Array<number> = rowsChecked
        .map((isSelected: boolean, index: number) => (isSelected ? index : false))
        .filter((isSelected: number | false) => typeof isSelected === 'number');

      this.selectionIndexes.emit(onlyCheckedRowsIdx);
    });
  }

  private setRowCheckboxStateOnHeaderCheckboxChange(
    headerCheckbox: FormControl,
    rowsCheckboxes: Array<FormControl>,
    refresh$: Observable<void>
  ): void {
    // @ts-ignore
    const headerCheckboxValueChanges$: Observable<boolean> = headerCheckbox.valueChanges.pipe(
      filter((checkboxValue: boolean | undefined) => checkboxValue !== undefined)
    );

    headerCheckboxValueChanges$.pipe(takeUntil(refresh$)).subscribe((isChecked: boolean) => {
      rowsCheckboxes.forEach((rowCheckbox: FormControl) => {
        rowCheckbox.setValue(isChecked);
      });
    });
  }

  private initTableResizeObserver(): void {
    this.tableResizeObserver = new ResizeObserver(() => {
      this.resizeTableHeaderCellSizeOutsideAngular();
    });

    this.tableResizeObserver.observe(this.tableElement.nativeElement);
  }

  private initTableSize(): void {
    this.tableSizeIntervalSubscription = interval(this.tableSizeInterval).subscribe(() => {
      if (this.hasNotTableHeaderOrContentCells()) {
        return;
      }

      this.resizeTableHeaderCellSizeOutsideAngular();
      this.tableSizeIntervalSubscription?.unsubscribe();
    });
  }

  private initCanToggleStateForHeader(): void {
    if (!this.tableRows?.length) {
      return;
    }

    this.canToggle = this.tableRows.some((tableRow: TableRowComponent) => {
      return tableRow.canToggle;
    });

    this.initCanToggleForTableHeader();
  }

  private initCanToggleForTableHeader(): void {
    if (!this.tableHeaders?.length) {
      return;
    }

    this.tableHeaders.forEach((tableHeader: TableHeaderComponent) => {
      tableHeader.canToggle = this.canToggle;
    });
  }

  private resizeTableHeaderCellSize(): void {
    if (this.hasNotTableHeaderOrContentCells()) {
      return;
    }

    const tableHeaderRowElement: Element | null = this.getTableHeaderRowElement();
    const tableContentRowElement: Element | null = this.getTableContentRowElement();
    const tableHeaderCells: HTMLCollection = (tableHeaderRowElement as Element).getElementsByTagName('app-table-header-cell');
    const tableRowCells: HTMLCollection = (tableContentRowElement as Element).getElementsByTagName('app-table-row-cell');

    for (let i: number = 0; i < tableRowCells.length; i++) {
      this.setTableHeaderCellSizeByRowCell(tableHeaderCells[i], tableRowCells[i]);
    }
  }

  private resizeTableHeaderCellSizeOutsideAngular(): void {
    this.ngZone.runOutsideAngular(() => {
      this.resizeTableHeaderCellSize();
    });
  }

  private getTableHeaderRowElement(): Element | null {
    return this.tableHeaderElement?.nativeElement.children.item(0);
  }

  private getTableContentRowElement(): Element | null {
    return this.tableContentElement?.nativeElement.children.item(0);
  }

  private hasNotTableHeaderOrContentCells(): boolean {
    const tableHeaderRowElement: Element | null = this.getTableHeaderRowElement();
    const tableContentRowElement: Element | null = this.getTableContentRowElement();

    return (
      !tableHeaderRowElement || !tableHeaderRowElement.children.length || !tableContentRowElement || !tableContentRowElement.children.length
    );
  }

  private clearTableHeaderCellSize(tableHeaderCell: Element): void {
    tableHeaderCell.setAttribute('style', '');
  }

  private setTableHeaderCellSizeByRowCell(tableHeaderCell: Element, tableRowCellElement: Element): void {
    this.clearTableHeaderCellSize(tableHeaderCell);

    const tableRowCellWidth: DOMRect = tableRowCellElement.getBoundingClientRect();

    tableHeaderCell.setAttribute('style', `width: ${tableRowCellWidth.width}px`);
  }

  private initScrollBarScrollListener(): void {
    if (!this.isScrollEnabled) {
      return;
    }

    this.subscription = this.scrollBar.scrolled.subscribe(() => {
      this.isVerticalScrollUsed = true;
    });
  }
}
