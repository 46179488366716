import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigate-to-operation-history-button[objectType]',
  templateUrl: './navigate-to-operation-history-button.component.html',
  styleUrls: ['./navigate-to-operation-history-button.component.scss'],
})
export class NavigateToOperationHistoryButtonComponent {
  @Input() public objectType!: string;
  @Input() public objectIdentity?: string;
  @Input() public isOutline: boolean;
  @Input() public buttonClass: string;

  constructor(private router: Router) {
    this.isOutline = false;
    this.buttonClass = 'gray-link';
  }

  public navigateToOperationHistorySearchPage(): void {
    this.router.navigate(['workshop/operation-history/search'], {
      queryParams: { objectType: this.objectType, objectIdentity: this.objectIdentity },
    });
  }
}
