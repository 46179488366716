import { DictionariesRolesDataPageDto } from '../../dtos/dictionaries-roles-data-page/dictionaries-roles-data-page.dto';
import { DictionariesRolesDataPageModel } from '../../models/dictionaries-roles-data-page/dictionaries-roles-data-page.model';

export class DictionariesRolesDataPageDtoCreator {
  public static create(model: DictionariesRolesDataPageModel): DictionariesRolesDataPageDto {
    return {
      ...(model.itemsPerPage !== undefined && { limit: model.itemsPerPage }),
      ...(model.page !== undefined && { page: model.page }),
      ...(model.isLimitRoles !== undefined && { limit_roles: Number(model.isLimitRoles) }),
    };
  }
}
