import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ModalService } from 'src/app/modal/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class NoFunctionalityService {
  constructor(private modalService: ModalService) {}

  public openNoFunctionalityModal(): Observable<boolean> {
    return this.modalService.openPopupModal('', 'noFunctionalityPopup.textKey', 'noFunctionalityPopup.successButtonLabelKey', '');
  }
}
