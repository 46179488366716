import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-with-textarea-modal',
  templateUrl: './popup-with-textarea-modal.component.html',
  styleUrls: ['./popup-with-textarea-modal.component.scss'],
})
export class PopupWithTextareaModalComponent implements OnInit {
  @Input() public titleKey: string;
  @Input() public textKey: string;
  @Input() public textareaLabelKey: string;
  @Input() public successButtonLabelKey: string;
  @Input() public cancelButtonLabelKey: string;
  @Input() public isRequired: boolean;

  public canCloseOnBackdropClick: boolean;
  public isCancelButton: boolean;
  public textFormControl: FormControl<string>;

  constructor(private activeModal: NgbActiveModal) {
    this.titleKey = '';
    this.textKey = '';
    this.textareaLabelKey = '';
    this.successButtonLabelKey = '';
    this.cancelButtonLabelKey = '';
    this.isRequired = false;
    this.canCloseOnBackdropClick = true;
    this.isCancelButton = true;
    this.textFormControl = new FormControl();
  }

  public ngOnInit(): void {
    this.initIsCancelButtonState();
    this.setFormControlValidators();
  }

  public buttonClick(action: boolean): void {
    const result: string = action ? this.textFormControl.value : '';

    this.activeModal.close(result);
  }

  private setFormControlValidators(): void {
    const validators: Array<ValidatorFn> = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    this.textFormControl.setValidators(validators);
  }

  private initIsCancelButtonState(): void {
    this.isCancelButton = this.cancelButtonLabelKey !== '';
  }
}
