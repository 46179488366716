import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent<CarouselItem> implements OnInit, AfterViewChecked, OnDestroy {
  @Input() public items!: Array<CarouselItem>;
  @Input() public title!: string;
  @Input() public itemWidth!: number;
  @Input() public itemMargin!: number;

  @ContentChild('itemTemplate') public itemTemplate!: TemplateRef<CarouselItem>;

  @ViewChild('carouselContainer', { static: true }) public carouselContainerElement!: ElementRef<HTMLElement>;
  @ViewChild('carouselItemsContainer', { static: true }) public carouselItemsContainerElement!: ElementRef<HTMLElement>;

  @ViewChildren('carouselItem') public carouselItemsElement!: QueryList<ElementRef<HTMLElement>>;

  private carouselContainerResizeObserver!: ResizeObserver;
  private carouselContainerWidth: number = 0;

  public ngOnInit(): void {
    this.setCarouselContainerWidth();
    this.initCarouselResizeObserver();
  }

  public ngAfterViewChecked(): void {
    this.initCarouselContainerHeight();
    this.initCarouselItemsValues();
  }

  public ngOnDestroy(): void {
    if (this.carouselContainerResizeObserver) {
      this.carouselContainerResizeObserver.unobserve(this.carouselContainerElement.nativeElement);
    }
  }

  public get isAllItemsVisible(): boolean {
    return this.carouselContainerWidth >= this.carouselItemsWidth;
  }

  public get isCarouselAtBeginning(): boolean {
    return this.currentCarouselPosition === this.minSlideValue;
  }

  public get isCarouselAtEnd(): boolean {
    return this.currentCarouselPosition === this.maxSlideValue;
  }

  public get itemsNumber(): number {
    return this.items.length;
  }

  public prev(): void {
    this.currentCarouselPosition = Math.min(this.currentCarouselPosition + this.slideValue, this.minSlideValue);
  }

  public next(): void {
    this.currentCarouselPosition = Math.max(this.currentCarouselPosition - this.slideValue, this.maxSlideValue);
  }

  public getContext(item: CarouselItem): unknown {
    return { item: item };
  }

  private get carouselItemsWidth(): number {
    if (!this.itemsNumber) {
      return 0;
    }

    const firstLastItemWidthWithMargin: number = this.itemMargin + this.itemWidth;
    const otherItemsWidthWithMargin: number = 2 * this.itemMargin + this.itemWidth;

    return 2 * firstLastItemWidthWithMargin + (this.itemsNumber - 2) * otherItemsWidthWithMargin;
  }

  private set currentCarouselPosition(value: number) {
    this.carouselItemsContainerElement.nativeElement.style.left = `${value}px`;
  }

  private get currentCarouselPosition(): number {
    return Number(this.carouselItemsContainerElement.nativeElement.style.left.replace('px', ''));
  }

  private get minSlideValue(): number {
    return 0;
  }

  private get maxSlideValue(): number {
    return this.carouselContainerElement.nativeElement.clientWidth - this.carouselItemsWidth;
  }

  private get slideValue(): number {
    return this.itemWidth + 2 * this.itemMargin;
  }

  private initCarouselResizeObserver(): void {
    this.carouselContainerResizeObserver = new ResizeObserver(() => {
      this.setCarouselContainerWidth();
      this.updatePosition(this.isAllItemsVisible);
    });

    this.carouselContainerResizeObserver.observe(this.carouselContainerElement.nativeElement);
  }

  private setCarouselContainerWidth(): void {
    this.carouselContainerWidth = this.carouselContainerElement.nativeElement.offsetWidth;
  }

  private updatePosition(isAllItemsVisible: boolean): void {
    this.carouselItemsContainerElement.nativeElement.style.transition = 'none';
    this.currentCarouselPosition = isAllItemsVisible ? this.minSlideValue : Math.max(this.currentCarouselPosition, this.maxSlideValue);
    this.carouselItemsContainerElement.nativeElement.style.transition = '1s';
  }

  private initCarouselItemsValues(): void {
    this.carouselItemsElement.forEach((el: ElementRef<HTMLElement>, index: number) => {
      const lastIndex: number = this.carouselItemsElement.length - 1;

      el.nativeElement.style.width = `${this.itemWidth}px`;
      el.nativeElement.style.marginLeft = `${index === 0 ? 0 : this.itemMargin}px`;
      el.nativeElement.style.marginRight = `${index === lastIndex ? 0 : this.itemMargin}px`;
    });
  }

  private initCarouselContainerHeight(): void {
    this.carouselContainerElement.nativeElement.style.height = `${this.carouselItemsContainerElement.nativeElement.clientHeight}px`;
  }
}
