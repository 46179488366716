import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsOptionKeysEnum } from '../../../recipes/enums/settings-option-keys/settings-option-keys.enum';
import { SettingsColorbox2kCollectionItemDto } from '../../dtos/settings-colorbox2k-collection-item/settings-colorbox2k-collection-item.dto';
import { SettingsColorbox2kCollectionDto } from '../../dtos/settings-colorbox2k-collection/settings-colorbox2k-collection.dto';
import { SettingsColorbox2kModel } from '../../models/settings-colorbox2k/settings-colorbox2k.model';
import { SettingsColorbox2kGroupModel } from '../../models/settings-colorbox2k-group/settings-colorbox2k-group.model';

export class SettingsColorbox2kGroupCreator {
  public static create(dto: SettingsGroupDto): SettingsColorbox2kGroupModel {
    return {
      id: dto.id,
      settings: this.convertCollectionsInOptions(dto.settings as Array<SettingsColorbox2kCollectionItemDto>),
    };
  }

  private static convertCollectionsInOptions(collection: Array<SettingsColorbox2kCollectionItemDto>): Array<SettingsColorbox2kModel> {
    const isSettingsColorChartsDto = (option: SettingsColorbox2kCollectionItemDto): option is SettingsColorbox2kCollectionDto =>
      option.key === SettingsOptionKeysEnum.show2kColorbox;

    const colorbox2kOptions: SettingsColorbox2kCollectionDto | undefined = collection.find(isSettingsColorChartsDto);
    const defaultColorChartsKey: string | undefined = collection.find(isSettingsColorChartsDto)?.value;

    if (!colorbox2kOptions) {
      return [];
    }

    return colorbox2kOptions.collection.map((colorbox2k: SettingsColorbox2kCollectionItemDto) =>
      this.convertSettingsColorbox2kCollectionToSettingsColorbox2k(colorbox2k, defaultColorChartsKey)
    );
  }

  private static convertSettingsColorbox2kCollectionToSettingsColorbox2k(
    colorbox2k: SettingsColorbox2kCollectionItemDto,
    defaultColorChartsKey: string | undefined
  ): SettingsColorbox2kModel {
    return {
      value: String(colorbox2k.key),
      label: colorbox2k.value,
      isDefault: String(colorbox2k.key) === defaultColorChartsKey,
    };
  }
}
