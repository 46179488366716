import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filters-box',
  templateUrl: './filters-box.component.html',
  styleUrls: ['./filters-box.component.scss'],
})
export class FiltersBoxComponent {
  @Input() public set hasFiltersValue(hasFiltersValue: boolean) {
    this.setHasClearFiltersButtonState(hasFiltersValue);
    this.setIsOpenState(hasFiltersValue);
  }

  @Input() public isOpen: boolean;

  @Output() public isClearFiltersButtonClicked: EventEmitter<void>;

  public hasClearFiltersButton: boolean;

  constructor() {
    this.hasClearFiltersButton = false;
    this.isOpen = false;
    this.isClearFiltersButtonClicked = new EventEmitter<void>();
  }

  public clearFiltersClick(): void {
    this.isClearFiltersButtonClicked.emit();
  }

  public toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }

  private setHasClearFiltersButtonState(hasFiltersValue: boolean): void {
    this.hasClearFiltersButton = hasFiltersValue;
  }

  private setIsOpenState(hasFiltersValue: boolean): void {
    if (!hasFiltersValue) {
      return;
    }

    this.isOpen = hasFiltersValue;
  }
}
