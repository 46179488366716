import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
})
export class GlobalLoaderComponent {
  public isLoading$: Observable<boolean>;

  constructor(protected loaderService: LoaderService) {
    this.isLoading$ = loaderService.getIsLoading();
  }
}
