<div class="table" #table>
  <header class="table-header" #tableHeader>
    <ng-content select="app-table-header"></ng-content>
  </header>

  <app-loader *ngIf="showLoader"></app-loader>

  <app-no-results class="table-no-results" [noResultsOptions]="noResultsOptions" *ngIf="showNoResults && !showLoader"></app-no-results>

  <ng-scrollbar
    #scrollbar="ngScrollbar"
    (updated)="scrollUpdated(scrollbar)"
    class="table-scrollbar"
    *ngIf="isScrollEnabled; else showStaticTable"
  >
    <ng-container *ngTemplateOutlet="tableContentRef"></ng-container>
  </ng-scrollbar>

  <ng-template #showStaticTable>
    <div>
      <ng-container *ngTemplateOutlet="tableContentRef"></ng-container>
    </div>
  </ng-template>

  <div class="scroll-hint" *ngIf="isVerticallyScrollable && scrollHint && isScrollEnabled && !isVerticalScrollUsed && !showLoader">
    <span>{{ scrollHint | translate }}</span>

    <button class="button-icon" (click)="scrollToBottom()">
      <span class="icon chevron-down-gray-24x24-icon"></span>
    </button>
  </div>
</div>

<ng-template #tableContentRef>
  <div class="table-content" [class.can-toggle]="canToggle" #tableContent>
    <ng-content select="app-table-row"></ng-content>
  </div>
</ng-template>
