import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoading$: BehaviorSubject<boolean>;

  constructor() {
    this.isLoading$ = new BehaviorSubject<boolean>(false);
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  public getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
