<div clas="show-loader-or-unexpected-error">
  <app-loader *ngIf="isLoading; else showUnexpectedError"></app-loader>

  <ng-template #showUnexpectedError>
    <app-unexpected-error
      *ngIf="!isLoading"
      [buttonText]="buttonText"
      [showButton]="showButton"
      (buttonClicked)="buttonClick()"
    ></app-unexpected-error>
  </ng-template>
</div>
