import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthTokenService } from '../../../auth/services/auth-token/auth-token.service';
import { SettingsService } from '../settings/settings.service';
import { SettingsOptionKeysEnum } from '../../../recipes/enums/settings-option-keys/settings-option-keys.enum';
import { SettingsModel } from '../../models/settings/settings.model';
import { SettingsOptionListItemModel } from '../../models/settings-option-list-item/settings-option-list-item.model';
import { WorkshopSettingsKeysModel } from '../../models/workshop-settings-keys/workshop-settings-keys.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentSettingsService {
  private currentSettings: BehaviorSubject<Array<SettingsModel> | null>;

  private readonly defaultRoundnessAccuracy: number;
  private readonly defaultDifficultToWeightThreshold: number;
  private readonly defaultPouringErrorThreshold: number;
  private readonly defaultCurrencyCode: string;
  private readonly defaultDateTimeFormat: string;
  private readonly defaultDateFormat: string;
  private readonly defaultTimeFormat: string;

  constructor(private settingsService: SettingsService, private authTokenService: AuthTokenService) {
    this.currentSettings = new BehaviorSubject<Array<SettingsModel> | null>(null);
    this.defaultRoundnessAccuracy = 1;
    this.defaultDifficultToWeightThreshold = 0.4;
    this.defaultPouringErrorThreshold = 0.1;
    this.defaultCurrencyCode = 'PLN';
    this.defaultDateTimeFormat = 'dd.MM.yyyy HH:mm';
    this.defaultDateFormat = 'dd.MM.yyyy';
    this.defaultTimeFormat = 'HH:mm';
  }

  public initSettings(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return this.updateCurrentSettings();
  }

  public getCurrentSettings(): Observable<Array<SettingsModel> | null> {
    return this.currentSettings.asObservable();
  }

  public getColorSystemsSettings(): Observable<Array<SettingsOptionListItemModel> | null> {
    return this.getCurrentSettings().pipe(
      map((settings: Array<SettingsModel> | null) => this.getSettingByColorSystemKey(settings)?.collection || null)
    );
  }

  public getDefaultColorSystem(): Observable<string | null> {
    return this.getCurrentSettings().pipe(
      map((settings: Array<SettingsModel> | null) => this.getSettingByColorSystemKey(settings)?.value || null)
    );
  }

  public getRoundnessAccuracy(): number {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const roundnessAccuracySettings: SettingsModel | null = this.getWorkshopSettingsByKey(
      settingsList,
      SettingsOptionKeysEnum.roundnessAccuracy
    );
    const roundnessAccuracy: number | null = this.getSettingsValueAsNumber(roundnessAccuracySettings);

    return roundnessAccuracy || this.defaultRoundnessAccuracy;
  }

  public getDefaultColorboxKey(): string {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const defaultColorboxSettings: SettingsModel | null = this.getWorkshopSettingsByKey(
      settingsList,
      SettingsOptionKeysEnum.defaultColorbox
    );

    return defaultColorboxSettings?.value || '';
  }

  public getShowColorBox2kValue(): string {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const show2kColorboxSetting: SettingsModel | null = this.getWorkshopSettingsByKey(settingsList, SettingsOptionKeysEnum.show2kColorbox);

    return show2kColorboxSetting?.value || '';
  }

  public getDifficultToWeightThreshold(): number | null {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const difficultToWeightThresholdSettings: SettingsModel | null = this.getWorkshopSettingsByKey(
      settingsList,
      SettingsOptionKeysEnum.difficultToWeightThreshold
    );
    const difficultToWeightThreshold: number | null = this.getSettingsValueAsNumber(difficultToWeightThresholdSettings);

    return difficultToWeightThreshold || this.defaultDifficultToWeightThreshold;
  }

  public getPouringErrorThreshold(): number {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const pouringErrorThresholdSettings: SettingsModel | null = this.getWorkshopSettingsByKey(
      settingsList,
      SettingsOptionKeysEnum.pouringErrorThreshold
    );
    const pouringErrorThreshold: number | null = this.getSettingsValueAsNumber(pouringErrorThresholdSettings);

    return pouringErrorThreshold || this.defaultPouringErrorThreshold;
  }

  public getCurrencyCode(): string {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const currencyCodeSettings: SettingsModel | null = this.getWorkshopSettingsByKey(settingsList, SettingsOptionKeysEnum.currencyCode);

    return currencyCodeSettings?.value || this.defaultCurrencyCode;
  }

  public getDateTimeFormat(): string {
    const settingsList: Array<SettingsModel> | null = this.currentSettings.value;
    const dateTimeFormatSettings: SettingsModel | null = this.getWorkshopSettingsByKey(settingsList, SettingsOptionKeysEnum.dateTimeFormat);

    return dateTimeFormatSettings?.value || this.defaultDateTimeFormat;
  }

  public getDateFormat(): string {
    const dateFormat: string = this.getDateTimeFormat().split(' ')[0];

    return dateFormat || this.defaultDateFormat;
  }

  public getTimeFormat(): string {
    const timeFormat: string = this.getDateTimeFormat().split(' ')[1];

    return timeFormat || this.defaultTimeFormat;
  }

  public updateCurrentSettings(): Observable<void> {
    return this.settingsService.getSettings().pipe(
      tap((currentSettings: Array<SettingsModel>) => {
        this.setCurrentSettings(currentSettings);
      }),
      map(() => void 0)
    );
  }

  public clearCurrentSettings(): void {
    this.currentSettings.next(null);
  }

  private getWorkshopSettingsByKey(settingsList: Array<SettingsModel> | null, key: WorkshopSettingsKeysModel): SettingsModel | null {
    if (!settingsList) {
      return null;
    }

    return settingsList.find((element: SettingsModel) => element.key === key) || null;
  }

  private getSettingsValueAsNumber(settings: SettingsModel | null): number | null {
    if (!settings || settings.value === '') {
      return null;
    }

    return Number(settings.value);
  }

  private getSettingByColorSystemKey(settings: Array<SettingsModel> | null): SettingsModel | null {
    if (!settings) {
      return null;
    }

    const key: WorkshopSettingsKeysModel = SettingsOptionKeysEnum.colorSystems;

    return settings.find((element: SettingsModel) => element.key === key) || null;
  }

  private setCurrentSettings(currentSettings: Array<SettingsModel>): void {
    this.currentSettings.next(currentSettings);
  }
}
