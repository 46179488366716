import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-additional-action-buttons',
  templateUrl: './additional-action-buttons.component.html',
  styleUrls: ['./additional-action-buttons.component.scss'],
})
export class AdditionalActionButtonsComponent {
  @ContentChild('itemTemplate') public itemTemplate!: TemplateRef<unknown>;
}
