import { SettingsOptionKeysEnum } from '../../../recipes/enums/settings-option-keys/settings-option-keys.enum';
import { AdditionalSettingsKeysDto } from '../../dtos/additional-settings-keys/additional-settings-keys.dto';
import { SettingsColorChartsKeysDto } from '../../dtos/settings-color-charts-keys/settings-color-charts-keys.dto';
import { SettingsEditFormDto } from '../../dtos/settings-edit-form/settings-edit-form.dto';
import { SettingsOptionEditFormDto } from '../../dtos/settings-option-edit-form/settings-option-edit-form.dto';
import { AdditionalSettingsDtoEnum } from '../../enums/additional-settings-dto/additional-settings.enum';
import { AdditionalSettingsEnum } from '../../enums/additional-settings/additional-settings.enum';
import { BarcodeScannerSettingsEditFormModel } from '../../models/barcode-scanner-settings-edit-form/barcode-scanner-settings-edit-form.model';
import { BarcodeScannerSettingsKeys } from '../../models/barcode-scanner-settings-keys/barcode-scanner-settings-keys.model';
import { DeviceCommunicationSoftwareSettingsKeysModel } from '../../models/device-communication-software-settings-keys/device-communication-software-settings-keys.model';
import { PrinterSettingsEditFormModel } from '../../models/printer-settings-edit-form/printer-settings-edit-form.model';
import { PrinterSettingsKeys } from '../../models/printer-settings-keys/printer-settings-keys.model';
import { ScaleSettingsEditFormModel } from '../../models/scale-settings-edit-form/scale-settings-edit-form.model';
import { ScaleSettingsKeysModel } from '../../models/scale-settings-keys/scale-settings-keys.model';
import { SettingsColorSystemsFormModel } from '../../models/settings-color-systems-form/settings-color-systems-form.model';
import { SettingsColorSystemsKeysModel } from '../../models/settings-color-systems-keys/settings-color-systems-keys.model';
import { SpectrophotometerSettingsEditFormModel } from '../../models/spectrophotometer-settings-edit-form/spectrophotometer-settings-edit-form.model';
import { SpectrophotometerSettingsKeysModel } from '../../models/spectrophotometer-settings-keys/spectrophotometer-settings-keys.model';
import { SettingsOptionEditFormDtoCreator } from '../settings-option-edit-form-dto/settings-option-edit-form-dto.creator';
import { SettingsColorbox2kKeysDto } from '../../dtos/settings-colorbox2k-keys/settings-colorbox2k-keys.dto';

export class SettingsEditFormDtoCreator {
  public static createFromBarcodeScannerSettingsEditForm(model: BarcodeScannerSettingsEditFormModel): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<BarcodeScannerSettingsKeys>('enabled', model.isEnabled ? 'true' : 'false'),
        SettingsOptionEditFormDtoCreator.create<BarcodeScannerSettingsKeys>('barcode_com_port', model.comPort ?? ''),
        SettingsOptionEditFormDtoCreator.create<BarcodeScannerSettingsKeys>('barcode_model', model.barcodeScannerModel ?? ''),
      ],
    };
  }

  public static createFromScaleSettingsEditForm(model: ScaleSettingsEditFormModel): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<ScaleSettingsKeysModel>('enabled', model.isEnabled ? 'true' : 'false'),
        SettingsOptionEditFormDtoCreator.create<ScaleSettingsKeysModel>('weight_com_port', model.comPort ?? ''),
        SettingsOptionEditFormDtoCreator.create<ScaleSettingsKeysModel>('weight_model', model.scaleModel ?? ''),
      ],
    };
  }

  public static createFromSpectrophotometerSettingsEditForm(model: SpectrophotometerSettingsEditFormModel): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<SpectrophotometerSettingsKeysModel>('enabled', model.isEnabled ? 'true' : 'false'),
        SettingsOptionEditFormDtoCreator.create<SpectrophotometerSettingsKeysModel>(
          'spectrophotometer_last_measurement_date',
          model.lastMeasurement ?? ''
        ),
        SettingsOptionEditFormDtoCreator.create<SpectrophotometerSettingsKeysModel>(
          'spectrophotometer_model',
          model.spectrophotometerModel ?? ''
        ),
        SettingsOptionEditFormDtoCreator.create<SpectrophotometerSettingsKeysModel>(
          'spectrophotometer_type',
          model.spectrophotometerType ?? ''
        ),
        SettingsOptionEditFormDtoCreator.create<SpectrophotometerSettingsKeysModel>(
          'spectrophotometer_serial_number',
          model.spectrophotometerSerialNumber ?? ''
        ),
      ],
    };
  }

  public static createFromPrinterSettingsEditForm(model: PrinterSettingsEditFormModel): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<PrinterSettingsKeys>('enabled', model.isEnabled ? 'true' : 'false'),
        SettingsOptionEditFormDtoCreator.create<PrinterSettingsKeys>('printer_name', model.printerName ?? ''),
      ],
    };
  }

  public static createFromDeviceConnectionSettingsEditForm(installedVersion: string): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<DeviceCommunicationSoftwareSettingsKeysModel>('installed_version', installedVersion),
      ],
    };
  }

  public static createDefaultColorSystemUpdate(defaultColorKey: string): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<SettingsColorSystemsKeysModel>(
          SettingsOptionKeysEnum.defaultColorSystem,
          `${defaultColorKey}`
        ),
      ],
    };
  }

  public static createDefaultColorChartUpdate(defaultChartKey: number): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<SettingsColorChartsKeysDto>(SettingsOptionKeysEnum.defaultColorbox, `${defaultChartKey}`),
      ],
    };
  }

  public static createColorbox2kUpdate(colorbox2kValue: string): SettingsEditFormDto {
    return {
      settings: [
        SettingsOptionEditFormDtoCreator.create<SettingsColorbox2kKeysDto>(SettingsOptionKeysEnum.show2kColorbox, `${colorbox2kValue}`),
      ],
    };
  }

  public static createColorSystemSettingsUpdate(colors: SettingsColorSystemsFormModel): SettingsEditFormDto {
    const settings: Array<SettingsOptionEditFormDto> = Object.keys(colors).map((colorKey: string) =>
      SettingsOptionEditFormDtoCreator.create<SettingsColorSystemsKeysModel>(`color_system_${colorKey}`, colors[+colorKey] ? 'true' : '')
    );

    return {
      settings,
    };
  }

  public static createAdditionalSettingUpdate(settingKey: AdditionalSettingsEnum, value: string | number): SettingsEditFormDto {
    let key: AdditionalSettingsKeysDto;

    switch (settingKey) {
      case AdditionalSettingsEnum.roundnessAccuracy:
        key = AdditionalSettingsDtoEnum.roundnessAccuracy;
        break;
      case AdditionalSettingsEnum.labelPrices:
        key = AdditionalSettingsDtoEnum.labelPrices;
        break;
      case AdditionalSettingsEnum.currencies:
        key = AdditionalSettingsDtoEnum.currencies;
        break;
      case AdditionalSettingsEnum.dateTimeFormats:
        key = AdditionalSettingsDtoEnum.dateTimeFormats;
        break;
      case AdditionalSettingsEnum.simpleRepairOrder:
        key = AdditionalSettingsDtoEnum.simpleRepairOrder;
        break;
      default:
        key = AdditionalSettingsDtoEnum.roundnessAccuracy;
    }

    return {
      settings: [SettingsOptionEditFormDtoCreator.create<AdditionalSettingsKeysDto>(key, `${value}`)],
    };
  }
}
