import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { DownloadFileCreator } from '../../../shared/creators/download-file/download-file.creator';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DownloadFileModel } from '../../../shared/models/download-file/download-file.model';
import { AdditionalSettingsGroupCreator } from '../../creators/additional-settings-group/additional-settings-group.creator';
import { BarcodeScannerSettingsCreator } from '../../creators/barcode-scanner-settings/barcode-scanner-settings.creator';
import { DeviceCommunicationSoftwareSettingsCreator } from '../../creators/device-communication-software-settings/device-communication-software-settings.creator';
import { PrinterSettingsCreator } from '../../creators/printer-settings/printer-settings.creator';
import { ScaleSettingsCreator } from '../../creators/scale-settings/scale-settings.creator';
import { SettingsColorChartsGroupCreator } from '../../creators/settings-color-charts-group/settings-color-charts-group.creator';
import { SettingsColorSystemsGroupCreator } from '../../creators/settings-color-systems-group/settings-color-systems-group.creator';
import { SettingsEditFormDtoCreator } from '../../creators/settings-edit-form-dto/settings-edit-form-dto.creator';
import { SettingsTabsCreator } from '../../creators/settings-tabs/settings-tabs.creator';
import { SettingsCreator } from '../../creators/settings/settings.creator';
import { SoftwareInformationSettingsCreator } from '../../creators/software-information-settings/software-information-settings.creator';
import { SpectrophotometerSettingsCreator } from '../../creators/spectrophotometer-settings/spectrophotometer-settings.creator';
import { DeviceCommunicationSoftwareSettingsDto } from '../../dtos/device-communication-software-settings/device-communication-software-settings.dto';
import { SettingsEditFormDto } from '../../dtos/settings-edit-form/settings-edit-form.dto';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsDto } from '../../dtos/settings/settings.dto';
import { SoftwareInformationSettingsDto } from '../../dtos/software-information-settings/software-information-settings.dto';
import { AdditionalSettingsEnum } from '../../enums/additional-settings/additional-settings.enum';
import { ImportOwnRecipeStrategyEnum } from '../../../own-recipes/enums/import-own-recipe-strategy/import-own-recipe-strategy.enum';
import { SettingsGroupEnum } from '../../enums/settings-group/settings-group.enum';
import { SettingsSubGroupEnum } from '../../enums/settings-sub-group/settings-sub-group.enum';
import { AdditionalSettingsGroupModel } from '../../models/additional-settings-group/additional-settings-group.model';
import { BarcodeScannerSettingsEditFormModel } from '../../models/barcode-scanner-settings-edit-form/barcode-scanner-settings-edit-form.model';
import { BarcodeScannerSettingsModel } from '../../models/barcode-scanner-settings/barcode-scanner-settings.model';
import { DeviceCommunicationSoftwareSettingsModel } from '../../models/device-communication-software-settings/device-communication-software-settings.model';
import { ImportOwnRecipeStrategyModel } from '../../../own-recipes/models/import-own-recipe-strategy/import-own-recipe-strategy.model';
import { PrinterSettingsEditFormModel } from '../../models/printer-settings-edit-form/printer-settings-edit-form.model';
import { PrinterSettingsModel } from '../../models/printer-settings/printer-settings.model';
import { ScaleSettingsEditFormModel } from '../../models/scale-settings-edit-form/scale-settings-edit-form.model';
import { ScaleSettingsModel } from '../../models/scale-settings/scale-settings.model';
import { SettingsColorChartsGroupModel } from '../../models/settings-color-charts-group/settings-color-charts-group.model';
import { SettingsColorSystemsFormModel } from '../../models/settings-color-systems-form/settings-color-systems-form.model';
import { SettingsColorSystemsGroupModel } from '../../models/settings-color-systems-group/settings-color-systems-group.model';
import { SettingsTabsModel } from '../../models/settings-tabs/settings-tabs.model';
import { SettingsModel } from '../../models/settings/settings.model';
import { SoftwareInformationSettingsModel } from '../../models/software-information-settings/software-information-settings.model';
import { SpectrophotometerSettingsEditFormModel } from '../../models/spectrophotometer-settings-edit-form/spectrophotometer-settings-edit-form.model';
import { SpectrophotometerSettingsModel } from '../../models/spectrophotometer-settings/spectrophotometer-settings.model';
import { SettingsHttpService } from '../settings-http/settings-http.service';
import { SettingsColorbox2kGroupCreator } from '../../creators/settings-colorbox2k-group/settings-colorbox2k-group.creator';
import { SettingsColorbox2kGroupModel } from '../../models/settings-colorbox2k-group/settings-colorbox2k-group.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private settingsHttpService: SettingsHttpService) {}

  public getSettings(): Observable<Array<SettingsModel>> {
    return this.settingsHttpService.getSettings().pipe(
      map((settingsDto: Array<SettingsDto>) => {
        return settingsDto.map((settingDto: SettingsDto) => SettingsCreator.create(settingDto));
      })
    );
  }

  public getDeviceCommunicationSoftwareSettings(): Observable<DeviceCommunicationSoftwareSettingsModel> {
    return this.settingsHttpService
      .getDeviceCommunicationSettings()
      .pipe(
        map((deviceCommunicationSoftwareSettingsDto: DeviceCommunicationSoftwareSettingsDto) =>
          DeviceCommunicationSoftwareSettingsCreator.create(deviceCommunicationSoftwareSettingsDto)
        )
      );
  }

  public getSoftwareInformationSettings(): Observable<SoftwareInformationSettingsModel> {
    return this.settingsHttpService
      .getSoftwareInformationSettings()
      .pipe(
        map((softwareInformationSettingsDto: SoftwareInformationSettingsDto) =>
          SoftwareInformationSettingsCreator.create(softwareInformationSettingsDto)
        )
      );
  }

  public getScaleSettings(): Observable<ScaleSettingsModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.devices, SettingsSubGroupEnum.weight)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => ScaleSettingsCreator.create(settingsGroupDto)));
  }

  public getColorSystemSettings(): Observable<SettingsColorSystemsGroupModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.colorSystems)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => SettingsColorSystemsGroupCreator.create(settingsGroupDto)));
  }

  public getAdditionalSettings(): Observable<AdditionalSettingsGroupModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.additionalSettings)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => AdditionalSettingsGroupCreator.create(settingsGroupDto)));
  }

  public getColorChartsSettings(): Observable<SettingsColorChartsGroupModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.formers)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => SettingsColorChartsGroupCreator.create(settingsGroupDto)));
  }

  public getColorBox2Settings(): Observable<SettingsColorbox2kGroupModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.formers)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => SettingsColorbox2kGroupCreator.create(settingsGroupDto)));
  }

  public getSpectrophotometerSettings(): Observable<SpectrophotometerSettingsModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.devices, SettingsSubGroupEnum.spectrophotometer)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => SpectrophotometerSettingsCreator.create(settingsGroupDto)));
  }

  public getBarcodeScannerSettings(): Observable<BarcodeScannerSettingsModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.devices, SettingsSubGroupEnum.barcodeScanner)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => BarcodeScannerSettingsCreator.create(settingsGroupDto)));
  }

  public getPrinterSettings(): Observable<PrinterSettingsModel> {
    return this.settingsHttpService
      .getGroupSettings(SettingsGroupEnum.devices, SettingsSubGroupEnum.printer)
      .pipe(map((settingsGroupDto: SettingsGroupDto) => PrinterSettingsCreator.create(settingsGroupDto)));
  }

  public getSettingsFile(settingsKey: string): Observable<DownloadFileModel> {
    return this.settingsHttpService
      .getSettingsFile(settingsKey)
      .pipe(map((downloadFileDto: DownloadFileDto) => DownloadFileCreator.create(downloadFileDto)));
  }

  public editDeviceCommunicationSettings(groupId: string, installedVersion: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto =
      SettingsEditFormDtoCreator.createFromDeviceConnectionSettingsEditForm(installedVersion);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public updateDefaultColorSystemSettings(defaultColorKey: string, groupId: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createDefaultColorSystemUpdate(defaultColorKey);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public updateColorSystemSettings(colors: SettingsColorSystemsFormModel, groupId: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createColorSystemSettingsUpdate(colors);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public updateAdditionalSettings(settingKey: AdditionalSettingsEnum, value: string | number, groupId: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createAdditionalSettingUpdate(settingKey, value);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public updateDefaultColorChartsSettings(value: number, groupId: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createDefaultColorChartUpdate(value);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public updateColorbox2kSetting(value: string, groupId: string): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createColorbox2kUpdate(value);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public editBarcodeScannerSettings(
    groupId: string,
    barcodeScannerSettingsEditForm: BarcodeScannerSettingsEditFormModel
  ): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto =
      SettingsEditFormDtoCreator.createFromBarcodeScannerSettingsEditForm(barcodeScannerSettingsEditForm);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public editScaleSettings(groupId: string, scaleSettingsEditForm: ScaleSettingsEditFormModel): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createFromScaleSettingsEditForm(scaleSettingsEditForm);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public editSpectrophotometerSettings(
    groupId: string,
    spectrophotometerSettingsEditForm: SpectrophotometerSettingsEditFormModel
  ): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto =
      SettingsEditFormDtoCreator.createFromSpectrophotometerSettingsEditForm(spectrophotometerSettingsEditForm);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public editPrinterSettings(groupId: string, printerSettingsEditForm: PrinterSettingsEditFormModel): Observable<void> {
    const settingsEditFormDto: SettingsEditFormDto = SettingsEditFormDtoCreator.createFromPrinterSettingsEditForm(printerSettingsEditForm);

    return this.settingsHttpService.editSettings(groupId, settingsEditFormDto);
  }

  public getSettingsTabs(): Observable<SettingsTabsModel> {
    return this.settingsHttpService.getSettingsTabs().pipe(map(SettingsTabsCreator.create));
  }

  public exportProductsPrices(): Observable<DownloadFileModel> {
    return this.settingsHttpService
      .exportProductPrices()
      .pipe(map((downloadFileDto: DownloadFileDto) => DownloadFileCreator.create(downloadFileDto)));
  }

  public getImportOwnRecipesStrategiesOptions(): Array<DropdownOptionModel<ImportOwnRecipeStrategyModel>> {
    return [
      {
        label: 'settings.importExport.import.recipes.append',
        value: ImportOwnRecipeStrategyEnum.append,
      },
      {
        label: 'settings.importExport.import.recipes.replace',
        value: ImportOwnRecipeStrategyEnum.removeAndAdd,
      },
    ];
  }
}
