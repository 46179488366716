import { SettingsOptionListItemDto } from '../../dtos/settings-option-list-item/settings-option-list-item.dto';
import { ColorSystemsUtil } from '../../utils/color-systems/color-systems.util';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsColorSystemsGroupModel } from '../../models/settings-color-systems-group/settings-color-systems-group.model';
import { SettingsColorSystemModel } from '../../models/settings-color-system/settings-color-system.model';
import { SettingsColorSystemDto } from '../../dtos/settings-color-system/settings-color-system.dto';
import { SettingsColorSystemsDefaultDto } from '../../dtos/settings-color-system-default/settings-color-system-default.dto';
import { SettingsColorSystemsDto } from '../../dtos/settings-color-systems/settings-color-systems.dto';
import { SettingsColorSystemsCollectionItemDto } from '../../dtos/settings-color-systems-collection-item/settings-color-systems-collection-item.dto';

export class SettingsColorSystemsGroupCreator {
  public static create(dto: SettingsGroupDto): SettingsColorSystemsGroupModel {
    return {
      id: dto.id,
      settings: this.colorSystemsSettingsCollection(dto.settings as Array<SettingsColorSystemsCollectionItemDto>),
    };
  }

  private static colorSystemsSettingsCollection(collection: Array<SettingsColorSystemsCollectionItemDto>): Array<SettingsColorSystemModel> {
    const isSelectedSettingsColorSystemDto = (option: SettingsColorSystemsCollectionItemDto): option is SettingsColorSystemDto =>
      option.key.includes('color_system_') && option.value === 'true';
    const isSettingsColorSystemsDefaultDto = (option: SettingsColorSystemsCollectionItemDto): option is SettingsColorSystemsDefaultDto =>
      option.key === 'default_color_system';
    const isSettingsColorSystemsDto = (option: SettingsColorSystemsCollectionItemDto): option is SettingsColorSystemsDto =>
      option.key === 'color_systems';

    const colorSystems: SettingsColorSystemsDto | undefined = collection.find(isSettingsColorSystemsDto);
    const defaultColorSystem: SettingsColorSystemsCollectionItemDto | undefined = collection.find(isSettingsColorSystemsDefaultDto);
    const settingColorSystemSelected: Array<SettingsColorSystemDto> = collection.filter(isSelectedSettingsColorSystemDto);

    if (!colorSystems) {
      return [];
    }

    return colorSystems.collection.map((colorSystem: SettingsOptionListItemDto) => {
      return {
        value: +colorSystem.key,
        label: colorSystem.value,
        isInSystem: ColorSystemsUtil.isColorSelectedInColorSystem(settingColorSystemSelected, +colorSystem.key),
        isDefault: ColorSystemsUtil.isColorSystemDefault(defaultColorSystem, colorSystem.key.toString()),
      } as SettingsColorSystemModel;
    });
  }
}
