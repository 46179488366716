<div class="icons">
  <span
    class="icon not-available-red-icon not-available"
    placement="top"
    *ngIf="isWithdraw"
    [ngbTooltip]="'shared.ingredientsTooltips.isWithdraw' | translate"
  ></span>

  <span
    class="icon powder-gray-icon"
    placement="top"
    *ngIf="isPowder"
    [ngbTooltip]="'shared.ingredientsTooltips.isPowder' | translate"
  ></span>

  <span
    class="icon special-gray-icon"
    placement="top"
    *ngIf="isSpecialIngredient"
    [ngbTooltip]="'shared.ingredientsTooltips.isSpecialIngredient' | translate"
  ></span>

  <span
    class="icon hardener-special-24x24 warning"
    placement="top"
    *ngIf="isHardener"
    [ngbTooltip]="'shared.ingredientsTooltips.isHardener' | translate"
  ></span>

  <span
    class="icon thinner-special-24x24 warning"
    placement="top"
    *ngIf="isThinner"
    [ngbTooltip]="'shared.ingredientsTooltips.isThinner' | translate"
  ></span>

  <span
    class="icon warning-triangle-gray-24x24 warning"
    placement="top"
    *ngIf="hasWarning"
    [ngbTooltip]="'shared.ingredientsTooltips.hasWarning' | translate"
  ></span>
</div>
