import { EnvironmentModel } from '../app/core/models/environment/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  version: '1.8.1.1',
  api: {
    url: 'https://dev-ncolor-api.novol.com/api',
  },
  connector: {
    url: 'http://localhost:51978',
    enabled: true,
  },
  xrite: {
    host: 'dev-ncolor-ws.novol.com',
  },
  supportUrl: 'https://novol.atlassian.net/servicedesk/customer/portal/3/group/27',
};
