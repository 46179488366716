import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RepairAssignedToMixtureModel } from '../../../mixes/models/repair-assigned-to-mixture/repair-assigned-to-mixture.model';
import { AlertTypesEnum } from '../../enums/alert-types/alert-types.enum';
import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';
import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';

@Component({
  selector: 'app-selected-repair-alert[repair]',
  templateUrl: './selected-repair-alert.component.html',
  styleUrls: ['./../alert/alert.component.scss'],
})
export class SelectedRepairAlertComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public repair!: RepairAssignedToMixtureModel;
  @Input() public showCancelButton: boolean;

  @Output() public cancelButtonClicked: EventEmitter<void>;

  public alertTypes: typeof AlertTypesEnum;
  public isBpFlowEnabled: boolean;

  constructor(private currentUserService: CurrentUserService) {
    super();

    this.alertTypes = AlertTypesEnum;
    this.showCancelButton = false;
    this.isBpFlowEnabled = true;

    this.cancelButtonClicked = new EventEmitter();
  }

  public ngOnInit(): void {
    this.initIsBpFlowEnabled();
  }

  public onCancelClick(): void {
    this.cancelButtonClicked.emit();
  }

  private initIsBpFlowEnabled(): void {
    this.subscription = this.currentUserService.getCurrentUser().subscribe((currentUser: CurrentUserModel | null) => {
      if (!currentUser) {
        return;
      }

      this.isBpFlowEnabled = currentUser.isBpFlowIntegrationEnabled;
    });
  }
}
