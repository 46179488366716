import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent {
  @Input() public hasCheckbox: boolean;

  public canToggle: boolean;
  public checkboxForm: FormControl<boolean | undefined>;

  constructor() {
    this.canToggle = false;
    this.hasCheckbox = false;
    this.checkboxForm = new FormControl<boolean | undefined>(false, { nonNullable: true });
  }
}
