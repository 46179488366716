<div class="unexpected-error">
  <div class="unexpected-error-icon">
    <span class="icon warning-triangle-gray-24x24"></span>
  </div>

  <span class="unexpected-error-title">
    {{ 'shared.unexpectedError.title' | translate }}
  </span>

  <button *ngIf="showButton" class="button red-outline" (click)="buttonClick()">
    {{ buttonText | translate }}
  </button>
</div>
