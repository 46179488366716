import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-unexpected-error',
  templateUrl: './unexpected-error.component.html',
  styleUrls: ['./unexpected-error.component.scss'],
})
export class UnexpectedErrorComponent {
  @Input() public buttonText: string;
  @Input() public showButton: boolean;

  @Output() public buttonClicked: EventEmitter<void>;

  constructor() {
    this.buttonText = 'shared.unexpectedError.buttonText';
    this.showButton = true;
    this.buttonClicked = new EventEmitter<void>();
  }

  public buttonClick(): void {
    this.buttonClicked.emit();
  }
}
