import { SettingsOptionListItemDto } from '../../dtos/settings-option-list-item/settings-option-list-item.dto';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { AdditionalSettingsOptionsModel } from '../../models/additilional-settings-options/additional-settings-options.model';
import { AdditionalSettingsGroupModel } from '../../models/additional-settings-group/additional-settings-group.model';
import { AdditionalSettingsDto } from '../../dtos/additional-settings/additional-settings.dto';
import { AdditionalSettingsLabelPricesDto } from '../../dtos/additional-settings-label-prices/additional-settings-label-prices.dto';
import { AdditionalSettingsRoundnessAccuracyDto } from '../../dtos/additional-settings-roundness-accuracy/additional-settings-roundness-accuracy.dto';
import { AdditionalSettingsLabelPricesCollectionItemDto } from '../../dtos/additional-settings-label-prices-collection-item/additional-settings-label-prices-collection-item.dto';
import { AdditionalSettingsCurrenciesDto } from '../../dtos/additional-settings-currencies/additional-settings-currencies.dto';
import { AdditionalSettingsDateTimeFormatsDto } from '../../dtos/additional-settings-date-time-formats/additional-settings-date-time-formats.dto';
import { AdditionalSettingsDtoEnum } from '../../enums/additional-settings-dto/additional-settings.enum';
import { AdditionalSettingsSimpleRepairOrderDto } from '../../dtos/additional-settings-simple-repair-order/additional-settings-simple-repair-order.dto';
import { AdditionalSettingsSimpleRepairOrderCollectionItemDto } from '../../dtos/additional-settings-simple-repair-order-collection-item/additional-settings-simple-repair-order-collection-item.dto';

export class AdditionalSettingsGroupCreator {
  public static create(dto: SettingsGroupDto): AdditionalSettingsGroupModel {
    const settingsDto: Array<AdditionalSettingsDto> = dto.settings as Array<AdditionalSettingsDto>;

    const labelPrices: AdditionalSettingsLabelPricesDto | undefined = settingsDto.find(
      ({ key }: AdditionalSettingsDto) => key === AdditionalSettingsDtoEnum.labelPrices
    ) as AdditionalSettingsLabelPricesDto;

    const simpleRepairOrder: AdditionalSettingsSimpleRepairOrderDto | undefined = settingsDto.find(
      ({ key }: AdditionalSettingsDto) => key === AdditionalSettingsDtoEnum.simpleRepairOrder
    ) as AdditionalSettingsSimpleRepairOrderDto;

    const roundnessAccuracy: AdditionalSettingsRoundnessAccuracyDto | undefined = settingsDto.find(
      ({ key }: AdditionalSettingsDto) => key === AdditionalSettingsDtoEnum.roundnessAccuracy
    ) as AdditionalSettingsRoundnessAccuracyDto;

    const dateTimeFormats: AdditionalSettingsDateTimeFormatsDto | undefined = settingsDto.find(
      ({ key }: AdditionalSettingsDto) => key === AdditionalSettingsDtoEnum.dateTimeFormats
    ) as AdditionalSettingsDateTimeFormatsDto;

    const currencies: AdditionalSettingsCurrenciesDto | undefined = settingsDto.find(
      ({ key }: AdditionalSettingsDto) => key === AdditionalSettingsDtoEnum.currencies
    ) as AdditionalSettingsCurrenciesDto;

    return {
      id: dto.id,
      labelPrices: AdditionalSettingsGroupCreator.getAdditionalSettingLabelPricesOptions(
        labelPrices
      ) as AdditionalSettingsOptionsModel<string>,
      simpleRepairOrder: AdditionalSettingsGroupCreator.getAdditionalSettingSimpleRepairOrderOptions(
        simpleRepairOrder
      ) as AdditionalSettingsOptionsModel<string>,
      roundnessAccuracy: AdditionalSettingsGroupCreator.getAdditionalSettingRoundnessAccuracyOptions(
        roundnessAccuracy
      ) as AdditionalSettingsOptionsModel<number>,
      dateTimeFormats: AdditionalSettingsGroupCreator.getAdditionalSettingDateTimeFormatsOptions(
        dateTimeFormats
      ) as AdditionalSettingsOptionsModel<string>,
      currencies: AdditionalSettingsGroupCreator.getAdditionalSettingDateCurrenciesOptions(
        currencies
      ) as AdditionalSettingsOptionsModel<string>,
    };
  }

  private static getAdditionalSettingRoundnessAccuracyOptions(
    setting?: AdditionalSettingsRoundnessAccuracyDto
  ): AdditionalSettingsOptionsModel<number> | null {
    if (!setting) {
      return null;
    }

    return {
      value: +setting.value,
      options: setting.collection.map(({ value, key }: SettingsOptionListItemDto) => ({ label: value.toString(), value: +key })),
    };
  }

  private static getAdditionalSettingLabelPricesOptions(
    setting?: AdditionalSettingsLabelPricesDto
  ): AdditionalSettingsOptionsModel<string> | null {
    if (!setting) {
      return null;
    }
    return {
      value: setting.value,
      options: setting.collection.map(({ value, key }: AdditionalSettingsLabelPricesCollectionItemDto) => ({ label: value, value: key })),
    };
  }

  private static getAdditionalSettingSimpleRepairOrderOptions(
    setting?: AdditionalSettingsSimpleRepairOrderDto
  ): AdditionalSettingsOptionsModel<string> | null {
    if (!setting) {
      return null;
    }
    return {
      value: setting.value,
      options: setting.collection.map(({ value, key }: AdditionalSettingsSimpleRepairOrderCollectionItemDto) => ({
        label: value,
        value: key,
      })),
    };
  }

  private static getAdditionalSettingDateTimeFormatsOptions(
    setting?: AdditionalSettingsDateTimeFormatsDto
  ): AdditionalSettingsOptionsModel<string> | null {
    if (!setting) {
      return null;
    }

    return {
      value: setting.value,
      options: setting.collection.map(({ value, key }: SettingsOptionListItemDto) => ({ label: value, value: key.toString() })),
    };
  }

  private static getAdditionalSettingDateCurrenciesOptions(
    setting?: AdditionalSettingsCurrenciesDto
  ): AdditionalSettingsOptionsModel<string> | null {
    if (!setting) {
      return null;
    }

    return {
      value: setting.value,
      options: setting.collection.map(({ value, key }: SettingsOptionListItemDto) => ({ label: value, value: key.toString() })),
    };
  }
}
