import { SettingsOptionModel } from '../models/settings-option/settings-option.model';

export class SettingsHelper<KeyType extends string> {
  private options: Array<SettingsOptionModel>;

  constructor(options: Array<SettingsOptionModel>) {
    this.options = options;
  }

  public getSettingOption<SettingsOptionType extends SettingsOptionModel>(key: KeyType): SettingsOptionType | null {
    const matchedOption: SettingsOptionModel | undefined = this.options.find((option: SettingsOptionModel) => option.key === key);

    if (matchedOption) {
      return matchedOption as SettingsOptionType;
    }

    return null;
  }

  public getSettingValue(key: KeyType): string | null {
    const settingOption: SettingsOptionModel | null = this.getSettingOption<SettingsOptionModel>(key);

    return settingOption?.value ?? null;
  }
}
