import { Injectable } from '@angular/core';

import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { DateUtil } from '../../../shared/utils/date/date.util';

@Injectable({
  providedIn: 'root',
})
export class FormControlDatepickerDateFormatterService extends NgbDateParserFormatter {
  constructor(private currentSettingsService: CurrentSettingsService) {
    super();
  }

  public parse(value: string | null, dateFormat: string = this.currentSettingsService.getDateFormat()): NgbDate | null {
    if (!value) {
      return null;
    }

    const date: Date = DateUtil.parse(value, dateFormat);

    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();

    return new NgbDate(year, month, day);
  }

  public format(date: NgbDate | null, dateFormat: string = this.currentSettingsService.getDateFormat()): string {
    if (!date) {
      return '';
    }

    const dateData: Date = new Date(date.year, date.month - 1, date.day);

    return DateUtil.formatDateToString(dateData, dateFormat);
  }

  public getYesterdayDate(): NgbDateStruct {
    const yesterday: Date = DateUtil.yesterday();

    return {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    };
  }

  public getTodayDate(): NgbDateStruct {
    const today: Date = DateUtil.today();

    return {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
  }
}
