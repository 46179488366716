import { AfterViewChecked, Component, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlBaseComponent } from '../form-control-base/form-control-base.component';

@Component({
  selector: 'app-form-control-textarea',
  templateUrl: './form-control-textarea.component.html',
})
export class FormControlTextareaComponent extends FormControlBaseComponent<string> implements OnInit, AfterViewChecked {
  @ViewChild('textarea') public textAreaElement!: ElementRef<HTMLTextAreaElement>;

  @Input() public maxlength: number | null;
  @Input() public placeholder: string;
  @Input() public autoScrollBottom: boolean;

  public isFocus: boolean;

  constructor(@Optional() ngControl: NgControl) {
    super(ngControl);

    this.maxlength = null;
    this.placeholder = '';
    this.isFocus = false;
    this.autoScrollBottom = false;
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public ngAfterViewChecked(): void {
    this.autoScrollContentBottom();
  }

  public onFocus(): void {
    this.isFocus = true;
  }

  public onBlur(): void {
    this.isFocus = false;

    this.onTouched();
  }

  public textareaChange(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  private autoScrollContentBottom(): void {
    if (this.autoScrollBottom) {
      this.textAreaElement.nativeElement.scrollTop = this.textAreaElement.nativeElement.scrollHeight;
    }
  }
}
