<div class="no-results">
  <ng-container *ngIf="noResultsOptions">
    <div class="no-results-icon" [class]="noResultsOptions.iconBackgroundClass">
      <span class="icon" [class]="noResultsOptions.iconClass"></span>
    </div>

    <span class="no-results-title">
      {{ noResultsOptions.title | translate }}
    </span>

    <p class="no-results-description">
      {{ noResultsOptions.description | translate }}
    </p>

    <button class="button black-outline" (click)="buttonClick(noResultsOptions!.buttonAction!)" *ngIf="canShowButton">
      {{ noResultsOptions!.buttonText! | translate }}
    </button>
  </ng-container>
</div>
