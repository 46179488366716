import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { LoaderComponent } from '../../components/loader/loader.component';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective {
  @Input() public set appLoading(loading: boolean) {
    this.vcRef.clear();

    if (loading) {
      this.showLoader();
    } else {
      this.showContent();
    }
  }

  constructor(private templateRef: TemplateRef<unknown>, private vcRef: ViewContainerRef) {}

  private showLoader(): void {
    this.vcRef.createComponent(LoaderComponent);
  }

  private showContent(): void {
    this.vcRef.createEmbeddedView(this.templateRef);
  }
}
