import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';

import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public set searchQuery(query: string | undefined) {
    this.setCurrentSearchQuery(query);
  }
  @Input() public set isOptionMenuOpen(isMenuOpen: boolean) {
    if (!isMenuOpen) {
      return;
    }

    this.setFocusToSearchBoxInput();
  }
  @Input() public placeholder: string;

  @Output() public searchQueryChange: EventEmitter<string>;

  @ViewChild('searchBoxInput') public searchBoxInput: ElementRef | null;

  public searchBoxControl: UntypedFormControl;

  private readonly searchQueryChangeDebounceTime: number;

  constructor() {
    super();

    this.placeholder = 'shared.searchBox.input.placeholder.search';
    this.searchQueryChange = new EventEmitter<string>();
    this.searchBoxControl = new UntypedFormControl();
    this.searchQueryChangeDebounceTime = 350;
    this.searchBoxInput = null;
  }

  public ngOnInit(): void {
    this.listenToSearchQueryFormChanges();
  }

  private setFocusToSearchBoxInput(): void {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      this.searchBoxInput?.nativeElement.focus();
    });
  }

  private setCurrentSearchQuery(query?: string): void {
    this.setSearchBoxControlValue(query);
  }

  private setSearchBoxControlValue(query?: string): void {
    this.searchBoxControl.setValue(query, { emitEvent: false });
  }

  private listenToSearchQueryFormChanges(): void {
    this.subscription = this.searchBoxControl.valueChanges.pipe(debounceTime(this.searchQueryChangeDebounceTime)).subscribe(() => {
      this.emitSearchQuery();
    });
  }

  private emitSearchQuery(): void {
    this.searchQueryChange.emit(this.searchBoxControl.value as string);
  }
}
