import { SettingsOptionListItemDto } from '../../dtos/settings-option-list-item/settings-option-list-item.dto';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsColorChartsCollectionDto } from '../../dtos/settings-color-charts-collection/settings-color-charts-collection.dto';
import { SettingsColorChartsDefaultDto } from '../../dtos/settings-color-charts-default/settings-color-charts-default.dto';
import { SettingsColorChartsDto } from '../../dtos/settings-color-charts/settings-color-charts.dto';
import { SettingsColorChartModel } from '../../models/settings-color-chart/settings-color-chart.model';
import { SettingsColorChartsGroupModel } from '../../models/settings-color-charts-group/settings-color-charts-group.model';
import { SettingsOptionKeysEnum } from '../../../recipes/enums/settings-option-keys/settings-option-keys.enum';

export class SettingsColorChartsGroupCreator {
  public static create(dto: SettingsGroupDto): SettingsColorChartsGroupModel {
    return {
      id: dto.id,
      settings: this.convertCollectionsInOptions(dto.settings as Array<SettingsColorChartsDto>),
    };
  }

  private static convertCollectionsInOptions(collection: Array<SettingsColorChartsDto>): Array<SettingsColorChartModel> {
    const isSettingsColorChartDefaultDto = (option: SettingsColorChartsDto): option is SettingsColorChartsDefaultDto =>
      option.key === SettingsOptionKeysEnum.defaultColorbox;
    const isSettingsColorChartsDto = (option: SettingsColorChartsDto): option is SettingsColorChartsCollectionDto =>
      option.key === SettingsOptionKeysEnum.colorboxes;

    const colorCharts: SettingsColorChartsCollectionDto | undefined = collection.find(isSettingsColorChartsDto);
    const defaultColorChartsKey: string | undefined = collection.find(isSettingsColorChartDefaultDto)?.value;

    if (!colorCharts) {
      return [];
    }

    return colorCharts.collection.map((colorSystem: SettingsOptionListItemDto) => {
      return {
        value: +colorSystem.key,
        label: colorSystem.value,
        isDefault: !!defaultColorChartsKey && +defaultColorChartsKey === colorSystem.key,
      };
    });
  }
}
