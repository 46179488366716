import { SupportedDeviceNameModel } from '../../../connector/models/supported-device-name/supported-device-name.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsHelper } from '../../helpers/settings.helper';
import { BarcodeScannerSettingsKeys } from '../../models/barcode-scanner-settings-keys/barcode-scanner-settings-keys.model';
import { BarcodeScannerSettingsModel } from '../../models/barcode-scanner-settings/barcode-scanner-settings.model';
import { SettingsGroupModel } from '../../models/settings-group/settings-group.model';
import { SettingsOptionCollectionListItemModel } from '../../models/settings-option-collection-list-item/settings-option-collection-list-item.model';
import { SettingOptionCollectionModel } from '../../models/settings-option-collection/settings-option-collection.model';
import { SettingOptionFileModel } from '../../models/settings-option-file/settings-option-file.model';
import { SettingOptionSingleModel } from '../../models/settings-option-single/settings-option-singe.model';
import { SettingsGroupCreator } from '../settings-group/settings-group.creator';

export class BarcodeScannerSettingsCreator {
  public static create(settingsGroupDto: SettingsGroupDto): BarcodeScannerSettingsModel {
    const settingsGroup: SettingsGroupModel = SettingsGroupCreator.create(settingsGroupDto);
    const settingsHelper: SettingsHelper<BarcodeScannerSettingsKeys> = new SettingsHelper<BarcodeScannerSettingsKeys>(
      settingsGroup.settings
    );

    const isEnabledOption: SettingOptionSingleModel | null = settingsHelper.getSettingOption<SettingOptionSingleModel>('enabled');
    const comPortListOption: SettingOptionCollectionModel | null =
      settingsHelper.getSettingOption<SettingOptionCollectionModel>('barcode_com_port');
    const scannerModelListOption: SettingOptionCollectionModel | null =
      settingsHelper.getSettingOption<SettingOptionCollectionModel>('barcode_model');
    const configurationCodesOption: SettingOptionFileModel | null =
      settingsHelper.getSettingOption<SettingOptionFileModel>('configuration_codes');
    const scannerModelListDropdownOptions: Array<DropdownOptionModel<SupportedDeviceNameModel>> =
      BarcodeScannerSettingsCreator.createDropdownOptions(scannerModelListOption);

    let scannerModelValue: SupportedDeviceNameModel = '' as SupportedDeviceNameModel;

    if (scannerModelListDropdownOptions.length) {
      scannerModelValue = scannerModelListDropdownOptions[0].value;
    }

    if (scannerModelListOption?.value) {
      scannerModelValue = scannerModelListOption.value as SupportedDeviceNameModel;
    }

    return {
      id: settingsGroup.id,
      name: settingsGroup.name,
      isEnabled: isEnabledOption?.value === 'true',
      comPort: {
        value: comPortListOption?.value ?? ('' as SupportedDeviceNameModel),
      },
      deviceModel: {
        list: scannerModelListDropdownOptions,
        value: scannerModelValue,
      },
      configurationCodes: {
        isDownloadButton: !!configurationCodesOption,
        settingsKey: configurationCodesOption?.key ?? '',
      },
    };
  }

  private static createDropdownOptions(
    settingOptionCollection: SettingOptionCollectionModel | null
  ): Array<DropdownOptionModel<SupportedDeviceNameModel>> {
    if (!settingOptionCollection) {
      return [];
    }

    return settingOptionCollection.collection.map((item: SettingsOptionCollectionListItemModel) => {
      return {
        label: item.value,
        value: item.key as SupportedDeviceNameModel,
      };
    });
  }
}
