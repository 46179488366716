import { FormControl } from '@angular/forms';

import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsColorSystemsGroupModel } from '../../models/settings-color-systems-group/settings-color-systems-group.model';
import { SettingsColorSystemDto } from '../../dtos/settings-color-system/settings-color-system.dto';
import { SettingsColorSystemModel } from '../../models/settings-color-system/settings-color-system.model';
import { SettingsColorSystemsCollectionItemDto } from '../../dtos/settings-color-systems-collection-item/settings-color-systems-collection-item.dto';

export class ColorSystemsUtil {
  public static get onlySystemColorOptions(): MonoTypeOperatorFunction<Array<SettingsColorSystemModel>> {
    return (observable: Observable<Array<SettingsColorSystemModel>>) =>
      observable.pipe(
        map((systems: Array<SettingsColorSystemModel>) => {
          return systems.filter(({ isInSystem }: SettingsColorSystemModel) => isInSystem);
        })
      );
  }

  public static get optionFormControl(): MonoTypeOperatorFunction<Array<SettingsColorSystemModel>> {
    return (observable: Observable<Array<SettingsColorSystemModel>>) =>
      observable.pipe(
        map((systems: Array<SettingsColorSystemModel>) => {
          return systems.map((system: SettingsColorSystemModel) => {
            const control: FormControl = new FormControl(system.isInSystem);

            return {
              ...system,
              control,
            };
          });
        })
      );
  }

  public static isColorSystemDefault(defaultSystem: SettingsColorSystemsCollectionItemDto | undefined, colorKey: string): boolean {
    return !!defaultSystem && defaultSystem.value === colorKey;
  }

  public static isColorSelectedInColorSystem(dto: Array<SettingsColorSystemDto>, colorKey: number): boolean {
    return dto.some((option: SettingsColorSystemsCollectionItemDto) => option.key === `color_system_${colorKey}`);
  }

  public static get mapToCollection(): OperatorFunction<SettingsColorSystemsGroupModel, Array<SettingsColorSystemModel>> {
    return (observable: Observable<SettingsColorSystemsGroupModel>) =>
      observable.pipe(map((system: SettingsColorSystemsGroupModel) => system.settings));
  }
}
