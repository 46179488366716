import { DictionaryRoleDto } from '../../dtos/dictionary-role/dictionary-role.dto';
import { DictionaryRoleModel } from '../../models/dictionary-role/dictionary-role.model';

export class DictionaryRoleCreator {
  public static create(dto: DictionaryRoleDto): DictionaryRoleModel {
    return {
      key: dto.key,
      label: dto.label,
      description: dto.description,
    };
  }
}
