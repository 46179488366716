import { SettingsGroupModel } from '../../models/settings-group/settings-group.model';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsGroupCreator } from '../settings-group/settings-group.creator';
import { SettingsHelper } from '../../helpers/settings.helper';
import { SpectrophotometerSettingsModel } from '../../models/spectrophotometer-settings/spectrophotometer-settings.model';
import { SpectrophotometerSettingsKeysModel } from '../../models/spectrophotometer-settings-keys/spectrophotometer-settings-keys.model';

export class SpectrophotometerSettingsCreator {
  public static create(settingsGroupDto: SettingsGroupDto): SpectrophotometerSettingsModel {
    const settingsGroup: SettingsGroupModel = SettingsGroupCreator.create(settingsGroupDto);
    const settingsHelper: SettingsHelper<SpectrophotometerSettingsKeysModel> = new SettingsHelper<SpectrophotometerSettingsKeysModel>(
      settingsGroup.settings
    );

    const isEnabled: boolean = settingsHelper.getSettingValue('enabled') === 'true';
    const lastMeasurement: string = settingsHelper.getSettingValue('spectrophotometer_last_measurement_date') ?? '';
    const spectrophotometerModel: string = settingsHelper.getSettingValue('spectrophotometer_model') ?? '';
    const spectrophotometerType: string = settingsHelper.getSettingValue('spectrophotometer_type') ?? '';
    const spectrophotometerSerialNumber: string = settingsHelper.getSettingValue('spectrophotometer_serial_number') ?? '';

    return {
      id: settingsGroup.id,
      name: settingsGroup.name,
      isEnabled,
      lastMeasurement,
      spectrophotometerModel,
      spectrophotometerSerialNumber,
      spectrophotometerType,
    };
  }
}
