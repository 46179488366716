<app-modal>
  <span modal-title>{{ titleKey | translate }}</span>

  <div modal-content>
    <span [innerHTML]="textKey | translate"></span>

    <app-form-control-textarea
      class="form-control"
      [label]="textareaLabelKey"
      [isRequiredAsteriskVisible]="isRequired"
      [formControl]="textFormControl"
    ></app-form-control-textarea>
  </div>

  <ng-container modal-footer>
    <button class="button black-outline" *ngIf="isCancelButton" (click)="buttonClick(false)">
      {{ cancelButtonLabelKey | translate }}
    </button>

    <button class="button red" (click)="buttonClick(true)" [disabled]="isRequired && textFormControl.invalid">
      {{ successButtonLabelKey | translate }}
    </button>
  </ng-container>
</app-modal>
