<div
  class="form-control-checkbox form-control"
  [ngClass]="{
    'is-indeterminate': isIndeterminate,
    'is-label': isLabel,
    'is-checked': isChecked,
    'is-disabled': isDisabled,
    'is-error': canShowErrors && control?.invalid
  }"
>
  <label class="checkbox">
    <span class="label">{{ label | translate }}</span>

    <input class="input" type="checkbox" [disabled]="isDisabled" [ngModel]="isChecked" (ngModelChange)="toggleCheckboxState()" />
  </label>

  <app-form-control-validation-errors
    class="errors-list"
    *ngIf="control?.errors as errors"
    [errors]="errors"
  ></app-form-control-validation-errors>
</div>
