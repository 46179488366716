import { SettingsTabsDto } from '../../dtos/settings-tabs/settings-tabs.dto';
import { SettingsTabsModel } from '../../models/settings-tabs/settings-tabs.model';

export class SettingsTabsCreator {
  public static create(dto: SettingsTabsDto): SettingsTabsModel {
    return {
      colorSystems: dto.color_systems,
      formers: dto.formers,
      devices: dto.devices,
      additionalSettings: dto.additional_settings,
      importExport: dto.import_export,
    };
  }
}
