import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LottieModule } from 'ngx-lottie';

import { TableComponent } from './components/table/table.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableRowCellComponent } from './components/table-row-cell/table-row-cell.component';
import { TableRowDetailsComponent } from './components/table-row-details/table-row-details.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableHeaderCellComponent } from './components/table-header-cell/table-header-cell.component';
import { FiltersBoxComponent } from './components/filters-box/filters-box.component';
import { SubscriptionStoreComponent } from './components/subscription-store/subscription-store.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingDirective } from './directives/loading/loading.directive';
import { AlertComponent } from './components/alert/alert.component';
import { ChipsComponent } from './components/chips/chips.component';
import { ColorThumbnailComponent } from './components/color-thumbnail/color-thumbnail.component';
import { IngredientTooltipsComponent } from './components/ingredient-tooltips/ingredient-tooltips.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { UnexpectedErrorComponent } from './components/unexpected-error/unexpected-error.component';
import { NavigateToOperationHistoryButtonComponent } from './components/navigate-to-operation-history-button/navigate-to-operation-history-button.component';
import { FileUploadDirective } from './directives/upload-file/file-upload.directive';
import { ShowLoaderOrUnexpectedErrorComponent } from './components/show-loader-or-unexpected-error/show-loader-or-unexpected-error.component';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { SelectedRepairAlertComponent } from './components/selected-repair-alert/selected-repair-alert.component';
import { AdditionalActionButtonsComponent } from './components/additional-action-buttons/additional-action-buttons.component';
import { ButtonWithLoaderComponent } from './components/button-with-loader/button-with-loader.component';
import { SupportButtonComponent } from './components/support-button/support-button.component';
import { RedactorXComponent } from './components/redactor-x/redactor-x.component';

@NgModule({
  declarations: [
    TableComponent,
    TableRowComponent,
    TableRowCellComponent,
    TableRowDetailsComponent,
    TableHeaderComponent,
    TableHeaderCellComponent,
    FiltersBoxComponent,
    SubscriptionStoreComponent,
    SearchBoxComponent,
    TabsComponent,
    LoaderComponent,
    LoadingDirective,
    NoResultsComponent,
    AlertComponent,
    ChipsComponent,
    ColorThumbnailComponent,
    IngredientTooltipsComponent,
    CarouselComponent,
    UnexpectedErrorComponent,
    NavigateToOperationHistoryButtonComponent,
    FileUploadDirective,
    ShowLoaderOrUnexpectedErrorComponent,
    GlobalLoaderComponent,
    SelectedRepairAlertComponent,
    AdditionalActionButtonsComponent,
    ButtonWithLoaderComponent,
    SupportButtonComponent,
    RedactorXComponent,
  ],
  exports: [
    TableComponent,
    TableRowComponent,
    TableRowCellComponent,
    TableRowDetailsComponent,
    TableHeaderComponent,
    TableHeaderCellComponent,
    FiltersBoxComponent,
    SubscriptionStoreComponent,
    SearchBoxComponent,
    TabsComponent,
    LoaderComponent,
    LoadingDirective,
    NoResultsComponent,
    AlertComponent,
    ChipsComponent,
    ColorThumbnailComponent,
    IngredientTooltipsComponent,
    CarouselComponent,
    UnexpectedErrorComponent,
    NavigateToOperationHistoryButtonComponent,
    FileUploadDirective,
    ShowLoaderOrUnexpectedErrorComponent,
    GlobalLoaderComponent,
    SelectedRepairAlertComponent,
    AdditionalActionButtonsComponent,
    ButtonWithLoaderComponent,
    SupportButtonComponent,
    RedactorXComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgScrollbarModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    LottieModule,
    NgbTooltipModule,
  ],
})
export class SharedModule {}
