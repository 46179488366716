import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UsersService } from '../users/users.service';
import { CurrentUserModel } from '../../models/current-user/current-user.model';
import { ActivateWorkshopAccountFormModel } from '../../../auth/models/activate-workshop-account-form/activate-workshop-account-form.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private currentUser$: BehaviorSubject<CurrentUserModel | null>;

  constructor(private usersService: UsersService) {
    this.currentUser$ = new BehaviorSubject<CurrentUserModel | null>(null);
  }

  public getCurrentUser(): Observable<CurrentUserModel | null> {
    return this.currentUser$.asObservable();
  }

  public getCurrentUserUsername(): Observable<string | null> {
    return this.currentUser$.pipe(map((user: CurrentUserModel | null) => user?.username || null));
  }

  public refreshCurrentUser(): Observable<void> {
    return this.usersService.getCurrentUser().pipe(
      tap((currentUser: CurrentUserModel) => {
        this.setCurrentUser(currentUser);
      }),
      map(() => void 0)
    );
  }

  public clearCurrentUser(): void {
    this.currentUser$.next(null);
  }

  public deleteCurrentUser(): Observable<void> {
    return this.usersService.deleteCurrentUserProfile();
  }

  public activateWorkshopAccount(value: ActivateWorkshopAccountFormModel): Observable<void> {
    return this.usersService.activateWorkshop(value).pipe(switchMap(() => this.refreshCurrentUser()));
  }

  private setCurrentUser(currentUser: CurrentUserModel): void {
    this.currentUser$.next(currentUser);
  }
}
