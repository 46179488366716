import { ActivateWorkshopAccountDto } from '../../../auth/dtos/activate-workshop-account/activate-workshop-account.dto';
import { ActivateWorkshopAccountFormModel } from '../../../auth/models/activate-workshop-account-form/activate-workshop-account-form.model';

export class ActivateWorkshopAccountDtoCreator {
  public static create(value: ActivateWorkshopAccountFormModel): ActivateWorkshopAccountDto {
    return {
      credentials: {
        password: value.password,
        repeat_password: value.repeatPassword,
      },
      details: {
        description: value.description,
        contact_person: value.contactPerson,
        phone: value.phone,
      },
    };
  }
}
