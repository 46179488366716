<div
  class="table-row-cell"
  [class.is-truncated]="isTextTruncated"
  [ngbTooltip]="tooltipText"
  [disableTooltip]="isTooltipDisabled"
  [openDelay]="tooltipOpenDelay"
  [class.is-overflow]="isOverflow"
  #tableCellContentWrapper
>
  <ng-content></ng-content>
</div>
