import { SupportedDeviceNameModel } from '../../../connector/models/supported-device-name/supported-device-name.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { SettingsGroupDto } from '../../dtos/settings-group/settings-group.dto';
import { SettingsHelper } from '../../helpers/settings.helper';
import { ScaleSettingsKeysModel } from '../../models/scale-settings-keys/scale-settings-keys.model';
import { ScaleSettingsModel } from '../../models/scale-settings/scale-settings.model';
import { SettingsGroupModel } from '../../models/settings-group/settings-group.model';
import { SettingsOptionCollectionListItemModel } from '../../models/settings-option-collection-list-item/settings-option-collection-list-item.model';
import { SettingOptionCollectionModel } from '../../models/settings-option-collection/settings-option-collection.model';
import { SettingOptionSingleModel } from '../../models/settings-option-single/settings-option-singe.model';
import { SettingsGroupCreator } from '../settings-group/settings-group.creator';

export class ScaleSettingsCreator {
  public static create(settingsGroupDto: SettingsGroupDto): ScaleSettingsModel {
    const settingsGroup: SettingsGroupModel = SettingsGroupCreator.create(settingsGroupDto);
    const settingsHelper: SettingsHelper<ScaleSettingsKeysModel> = new SettingsHelper<ScaleSettingsKeysModel>(settingsGroup.settings);

    const isEnabledOption: SettingOptionSingleModel | null = settingsHelper.getSettingOption<SettingOptionSingleModel>('enabled');
    const comPortListOption: SettingOptionCollectionModel | null =
      settingsHelper.getSettingOption<SettingOptionCollectionModel>('weight_com_port');
    const scannerModelListOption: SettingOptionCollectionModel | null =
      settingsHelper.getSettingOption<SettingOptionCollectionModel>('weight_model');
    const scaleModelListDropdownOptions: Array<DropdownOptionModel<string>> =
      ScaleSettingsCreator.createDropdownOptions(scannerModelListOption);

    return {
      id: settingsGroup.id,
      name: settingsGroup.name,
      isEnabled: isEnabledOption?.value === 'true',
      comPort: {
        value: comPortListOption?.value ?? '',
      },
      deviceModel: {
        list: scaleModelListDropdownOptions,
        value: (scannerModelListOption?.value as SupportedDeviceNameModel) ?? '',
      },
    };
  }

  private static createDropdownOptions(settingOptionCollection: SettingOptionCollectionModel | null): Array<DropdownOptionModel<string>> {
    if (!settingOptionCollection) {
      return [];
    }

    return settingOptionCollection.collection?.map((item: SettingsOptionCollectionListItemModel) => {
      return {
        label: item.value,
        value: item.key,
      };
    });
  }
}
