<div class="modal-container" [class.is-header]="isHeader" [class.is-footer]="isFooter" #modal>
  <header class="modal-header">
    <h2 class="modal-title" #titleWrapper>
      <ng-content select="span[modal-title]"></ng-content>
    </h2>

    <ng-content select="[header-content]"></ng-content>
  </header>

  <div
    class="modal-content"
    [class.has-content-side-padding]="hasContentSidePadding"
    [class.has-content-vertical-padding]="hasContentVerticalPadding"
  >
    <ng-content select="[modal-content]"></ng-content>
  </div>

  <footer class="modal-footer" #footerWrapper>
    <ng-content select="[modal-footer]"></ng-content>
  </footer>
</div>
