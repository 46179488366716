import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { DownloadFileDto } from '../../dtos/download-file/download-file.dto';
import { HttpResponseUtil } from '../../../core/utils/http-response/http-response.util';

export class DownloadFileDtoCreator {
  public static create(response: HttpResponse<Blob>): DownloadFileDto {
    const defaultFileName: string = 'file';
    const headers: HttpHeaders = response.headers;
    const contentDisposition: string | null = headers.get('content-disposition');
    const fileName: string = contentDisposition ? HttpResponseUtil.contentDispositionFileName(contentDisposition) : defaultFileName;

    return {
      body: response.body as Blob,
      fileName,
    } as DownloadFileDto;
  }
}
