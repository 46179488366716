import { Component, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

import { distinctUntilChanged, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  @Input() public canToggle: boolean;
  @Input() public detailsTemplate?: TemplateRef<unknown>;
  @Input() public hasCheckbox: boolean;

  @Output() public detailsOpenStateChange: EventEmitter<boolean>;
  @Output() public rowSelected: EventEmitter<void>;

  public checkboxForm: FormControl<boolean>;
  public isOpen: boolean;

  constructor() {
    this.canToggle = false;
    this.hasCheckbox = false;
    this.isOpen = false;
    this.checkboxForm = new FormControl(false, { nonNullable: true });
    this.detailsOpenStateChange = new EventEmitter<boolean>();
    this.rowSelected = new EventEmitter<void>();
  }

  @HostListener('click')
  public onTableRowClick(): void {
    this.emitSelected();
    this.toggleDetails();
  }

  public get checkboxValue$(): Observable<boolean> {
    return this.checkboxForm.valueChanges.pipe(startWith(this.checkboxForm.value), distinctUntilChanged());
  }

  private toggleDetails(): void {
    if (!this.canToggle) {
      return;
    }

    this.isOpen = !this.isOpen;

    this.emitDetailsOpenStateChange();
  }

  private emitDetailsOpenStateChange(): void {
    this.detailsOpenStateChange.emit(this.isOpen);
  }

  private emitSelected(): void {
    this.rowSelected.emit();
  }
}
