import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-with-loader',
  templateUrl: './button-with-loader.component.html',
  styleUrls: ['./button-with-loader.component.scss'],
})
export class ButtonWithLoaderComponent {
  @Input() public buttonText: string;
  @Input() public buttonColor: string;
  @Input() public isLoading: boolean;
  @Output() public buttonClicked: EventEmitter<void>;

  constructor() {
    this.buttonText = '';
    this.buttonColor = '';
    this.isLoading = false;
    this.buttonClicked = new EventEmitter<void>();
  }

  public onClick(): void {
    if (this.isLoading) {
      return;
    }

    this.buttonClicked.emit();
  }
}
