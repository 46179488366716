<app-modal>
  <span modal-title>{{ titleKey | translate }}</span>

  <div modal-content>
    <span [innerHTML]="textKey | translate"></span>

    <app-form-control-checkbox
      class="form-control"
      [label]="checkboxLabelKey"
      [(ngModel)]="isCheckboxChecked"
      [checkedValue]="true"
      [uncheckedValue]="false"
    ></app-form-control-checkbox>
  </div>

  <ng-container modal-footer>
    <button class="button red" (click)="buttonClick()">
      {{ buttonLabelKey | translate }}
    </button>
  </ng-container>
</app-modal>
