import { SoftwareInformationSettingsDto } from '../../dtos/software-information-settings/software-information-settings.dto';
import { SoftwareInformationSettingsModel } from '../../models/software-information-settings/software-information-settings.model';

export class SoftwareInformationSettingsCreator {
  public static create(softwareInformationSettingsDto: SoftwareInformationSettingsDto): SoftwareInformationSettingsModel {
    return {
      showNoInstalledSoftwarePopup: softwareInformationSettingsDto.show_no_installed_software_popup,
      showNewSoftwareVersionPopup: softwareInformationSettingsDto.show_new_software_version_popup,
      downloadSoftwareKey: softwareInformationSettingsDto.download_software_key,
    };
  }
}
