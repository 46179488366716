import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UsersHttpService } from '../users-http/users-http.service';
import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { CurrentUserCreator } from '../../creators/current-user/current-user.creator';
import { CurrentUserModel } from '../../models/current-user/current-user.model';
import { ActivateWorkshopAccountFormModel } from '../../../auth/models/activate-workshop-account-form/activate-workshop-account-form.model';
import { ActivateWorkshopAccountDto } from '../../../auth/dtos/activate-workshop-account/activate-workshop-account.dto';
import { ActivateWorkshopAccountDtoCreator } from '../../creators/activate-workshop-account-dto/activate-workshop-account-dto.creator';
import { DictionariesRolesDataPageDto } from '../../dtos/dictionaries-roles-data-page/dictionaries-roles-data-page.dto';
import { DictionaryRoleDto } from '../../dtos/dictionary-role/dictionary-role.dto';
import { ListModel } from '../../../shared/models/list/list.model';
import { DictionaryRoleModel } from '../../models/dictionary-role/dictionary-role.model';
import { DictionariesRolesDataPageDtoCreator } from '../../creators/dictionaries-roles-data-page-dto/dictionaries-roles-data-page-dto.creator';
import { DictionariesRolesDataPageModel } from '../../models/dictionaries-roles-data-page/dictionaries-roles-data-page.model';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { DictionaryRoleCreator } from '../../creators/dictionary-role/dictionary-role.creator';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private usersHttpService: UsersHttpService) {}

  public getCurrentUser(): Observable<CurrentUserModel> {
    return this.usersHttpService.getCurrentUser().pipe(map((dto: CurrentUserDto) => CurrentUserCreator.create(dto)));
  }

  public activateWorkshop(value: ActivateWorkshopAccountFormModel): Observable<void> {
    const dto: ActivateWorkshopAccountDto = ActivateWorkshopAccountDtoCreator.create(value);

    return this.usersHttpService.activateWorkshop(dto);
  }

  public deleteCurrentUserProfile(): Observable<void> {
    return this.usersHttpService.deleteCurrentUserProfile();
  }

  public getDictionaryRoleList(roleDictionariesDataPageModel: DictionariesRolesDataPageModel): Observable<ListModel<DictionaryRoleModel>> {
    const dataPageDto: DictionariesRolesDataPageDto = DictionariesRolesDataPageDtoCreator.create(roleDictionariesDataPageModel);

    return this.usersHttpService.getDictionaryRoleList(dataPageDto).pipe(
      map((listDto: ListDto<DictionaryRoleDto>) => {
        const items: Array<DictionaryRoleModel> = listDto.items.map((dictionaryRoleDto: DictionaryRoleDto) =>
          DictionaryRoleCreator.create(dictionaryRoleDto)
        );

        return ListCreator.create<DictionaryRoleDto, DictionaryRoleModel>(listDto, items);
      })
    );
  }
}
