import { Component, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlBaseComponent } from '../form-control-base/form-control-base.component';

@Component({
  selector: 'app-form-control-checkbox',
  templateUrl: './form-control-checkbox.component.html',
})
export class FormControlCheckboxComponent<CheckedValueType, UncheckedValueType> extends FormControlBaseComponent<
  CheckedValueType | UncheckedValueType
> {
  @Input() public checkedValue: CheckedValueType;
  @Input() public uncheckedValue: UncheckedValueType;
  @Input() public isIndeterminate: boolean;

  public isChecked: boolean;

  constructor(@Optional() ngControl: NgControl) {
    super(ngControl);

    this.checkedValue = true as unknown as CheckedValueType;
    this.uncheckedValue = null as unknown as UncheckedValueType;
    this.isChecked = false;
    this.isIndeterminate = false;
  }

  public toggleCheckboxState(): void {
    this.isChecked = !this.isChecked;

    const value: CheckedValueType | UncheckedValueType = this.isChecked ? this.checkedValue : this.uncheckedValue;

    this.onChange(value);
    this.onTouched();
  }

  public override writeValue(value: CheckedValueType | UncheckedValueType): void {
    this.isChecked = value === this.checkedValue;
  }
}
