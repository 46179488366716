import { DeviceCommunicationSoftwareSettingsModel } from '../../models/device-communication-software-settings/device-communication-software-settings.model';
import { DeviceCommunicationSoftwareSettingsDto } from '../../dtos/device-communication-software-settings/device-communication-software-settings.dto';

export class DeviceCommunicationSoftwareSettingsCreator {
  public static create(deviceCommunicationSoftwareDto: DeviceCommunicationSoftwareSettingsDto): DeviceCommunicationSoftwareSettingsModel {
    return {
      settingsGroupId: deviceCommunicationSoftwareDto.group_id,
      installedVersion: deviceCommunicationSoftwareDto.installed_version,
      latestVersion: deviceCommunicationSoftwareDto.latest_version,
      updateSoftware: {
        isInfo: deviceCommunicationSoftwareDto.update_software_label !== '',
        text: deviceCommunicationSoftwareDto.update_software_label,
      },
      downloadSoftware: {
        isDownloadButton: deviceCommunicationSoftwareDto.download_software_key !== '',
        settingsKey: deviceCommunicationSoftwareDto.download_software_key,
      },
    };
  }
}
