import { Component, EventEmitter, Input, Output } from '@angular/core';

import { take } from 'rxjs/operators';

import { TabModel } from '../../models/tab/tab.model';
import { NoFunctionalityService } from '../../services/no-functionality/no-functionality.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() public tabs: Array<TabModel>;

  @Output() public tabButtonClicked: EventEmitter<TabModel>;

  constructor(private noFunctionalityService: NoFunctionalityService) {
    this.tabs = [];
    this.tabButtonClicked = new EventEmitter<TabModel>();
  }

  public tabButtonClick(tab: TabModel): void {
    if (tab.disabled) {
      this.noFunctionalityService.openNoFunctionalityModal().pipe(take(1)).subscribe();

      return;
    }

    this.tabButtonClicked.emit(tab);
  }
}
