import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-table-row-details',
  templateUrl: './table-row-details.component.html',
  styleUrls: ['./table-row-details.component.scss'],
})
export class TableRowDetailsComponent {
  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
