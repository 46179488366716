import { Injectable, Type } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { PopupModalComponent } from '../components/popup-modal/popup-modal.component';
import { ModalSizeTypeModel } from '../models/modal-size-type.model';
import { ModalSizeEnum } from '../enums/modal-size.enum';
import { LoaderModalComponent } from '../components/loader-modal/loader-modal.component';
import { PopupWithCheckboxModalComponent } from '../components/popup-with-checkbox-modal/popup-with-checkbox-modal.component';
import { PopupWithTextareaModalComponent } from '../components/popup-with-textarea-modal/popup-with-textarea-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  public isAnyModalOpen(): boolean {
    return this.ngbModal.hasOpenModals();
  }

  public openPopupModal(
    titleKey: string,
    textKey: string,
    successButtonLabelKey: string,
    cancelButtonLabelKey?: string,
    canCloseOnBackdrop: boolean = true
  ): Observable<boolean> {
    const modal: NgbModalRef = this.openModal(PopupModalComponent, ModalSizeEnum.small, canCloseOnBackdrop);
    const instance: PopupModalComponent = modal.componentInstance as PopupModalComponent;

    instance.titleKey = titleKey;
    instance.textKey = textKey;
    instance.successButtonLabelKey = successButtonLabelKey;

    if (cancelButtonLabelKey) {
      instance.cancelButtonLabelKey = cancelButtonLabelKey;
    }

    return from(modal.result) as Observable<boolean>;
  }

  public openPopupWithCheckboxModal(
    titleKey: string,
    textKey: string,
    buttonLabelKey: string,
    checkboxLabelKey: string,
    canCloseOnBackdrop: boolean = true
  ): Observable<boolean> {
    const modal: NgbModalRef = this.openModal(PopupWithCheckboxModalComponent, ModalSizeEnum.small, canCloseOnBackdrop);
    const instance: PopupWithCheckboxModalComponent = modal.componentInstance as PopupWithCheckboxModalComponent;

    instance.titleKey = titleKey;
    instance.textKey = textKey;
    instance.buttonLabelKey = buttonLabelKey;
    instance.checkboxLabelKey = checkboxLabelKey;

    return from(modal.result) as Observable<boolean>;
  }

  public openPopupWithTextareaModal(
    titleKey: string,
    textKey: string,
    textareaLabelKey: string,
    successButtonLabelKey: string,
    cancelButtonLabelKey?: string,
    isRequired?: boolean,
    canCloseOnBackdrop: boolean = true
  ): Observable<string> {
    const modal: NgbModalRef = this.openModal(PopupWithTextareaModalComponent, ModalSizeEnum.small, canCloseOnBackdrop);
    const instance: PopupWithTextareaModalComponent = modal.componentInstance as PopupWithTextareaModalComponent;

    instance.titleKey = titleKey;
    instance.textKey = textKey;
    instance.textareaLabelKey = textareaLabelKey;
    instance.successButtonLabelKey = successButtonLabelKey;

    if (cancelButtonLabelKey) {
      instance.cancelButtonLabelKey = cancelButtonLabelKey;
    }

    if (isRequired !== undefined) {
      instance.isRequired = isRequired;
    }

    return from(modal.result) as Observable<string>;
  }

  public openModal(
    component: unknown,
    size: ModalSizeTypeModel = ModalSizeEnum.small,
    canCloseOnBackdrop: boolean = false,
    canCloseOnKeyboard: boolean = true
  ): NgbModalRef {
    return this.prepareModal(component, size, canCloseOnBackdrop, canCloseOnKeyboard);
  }

  public openModalWithData<T, R>(
    component: Type<T>,
    size: ModalSizeTypeModel = ModalSizeEnum.small,
    canCloseOnBackdrop: boolean = false,
    data?: Partial<T>
  ): Promise<R> {
    return this.prepareModalWithData(component, size, canCloseOnBackdrop, data).result;
  }

  public openLoaderModal(loadingHeader: string, loadingMsg: string): NgbModalRef {
    const modal: NgbModalRef = this.openModal(LoaderModalComponent);
    const modalComponent: LoaderModalComponent = modal.componentInstance;

    modalComponent.loadingHeader = loadingHeader;
    modalComponent.loadingMsg = loadingMsg;

    return modal;
  }

  public dismissAll(): void {
    this.ngbModal.dismissAll();
  }

  private prepareModal(
    component: unknown,
    size: ModalSizeTypeModel,
    canCloseOnBackdrop: boolean,
    canCloseOnKeyboard: boolean
  ): NgbModalRef {
    const backdrop: boolean | 'static' = canCloseOnBackdrop ? canCloseOnBackdrop : 'static';

    return this.ngbModal.open(component, {
      animation: false,
      centered: true,
      backdrop: backdrop,
      windowClass: `modal ${size}`,
      keyboard: canCloseOnKeyboard,
    });
  }

  private prepareModalWithData<T>(
    component: Type<T>,
    size: ModalSizeTypeModel,
    canCloseOnBackdrop: boolean,
    data?: Partial<T>
  ): NgbModalRef {
    const backdrop: boolean | 'static' = canCloseOnBackdrop || 'static';

    const modal: NgbModalRef = this.ngbModal.open(component, {
      animation: false,
      centered: true,
      backdrop: backdrop,
      windowClass: `modal ${size}`,
    });
    const modalComponent: T = modal.componentInstance;

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        // @ts-ignore
        modalComponent[key as keyof T] = value;
      }
    }

    return modal;
  }
}
