<app-table-header-cell *ngIf="hasCheckbox">
  <div
    class="form-control-checkbox form-control"
    [ngClass]="{ 'is-indeterminate': checkboxForm.value === undefined, 'is-checked': checkboxForm.value }"
  >
    <label class="checkbox">
      <input class="input" type="checkbox" [formControl]="checkboxForm" />
    </label>
  </div>
</app-table-header-cell>

<ng-content select="app-table-header-cell,ng-container"></ng-content>

<ng-container *ngIf="canToggle">
  <app-table-header-cell class="cell-toggle"></app-table-header-cell>
</ng-container>
