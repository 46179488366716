import { Component, Input, OnInit } from '@angular/core';

import { NoResultsOptionsModel } from '../../models/no-results/no-results-options.model';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements OnInit {
  @Input() public noResultsOptions?: NoResultsOptionsModel;

  public canShowButton?: boolean;

  public ngOnInit(): void {
    this.initNoResultsOptions();
  }

  public buttonClick(buttonAction: () => void): void {
    buttonAction();
  }

  private initNoResultsOptions(): void {
    if (!this.noResultsOptions) {
      this.noResultsOptions = {
        title: 'shared.noResults.noResultsForFilter.title',
        iconClass: 'search-gray-icon',
        description: 'shared.noResults.noResultsForFilter.description',
        buttonText: 'shared.noResults.noResultsForFilter.button',
        showButton: false,
      };
    }

    this.canShowButton = !!(this.noResultsOptions.showButton && this.noResultsOptions.buttonText && this.noResultsOptions.buttonAction);
  }
}
