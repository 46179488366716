import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-form-control-validation-errors',
  templateUrl: './form-control-validation-errors.component.html',
  styleUrls: ['./form-control-validation-errors.component.scss'],
})
export class FormControlValidationErrorsComponent {
  @Input() public errors!: ValidationErrors | ReadonlyMap<string, string>;
}
