import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { CurrentUserModel } from '../../models/current-user/current-user.model';

export class CurrentUserCreator {
  public static create(dto: CurrentUserDto): CurrentUserModel {
    return {
      username: dto.username,
      showOwnerFirstLoginView: dto.show_owner_first_login_view,
      isBpFlowIntegrationEnabled: dto.bp_flow_integration_enabled,
    };
  }
}
