import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { ActivateWorkshopAccountDto } from '../../../auth/dtos/activate-workshop-account/activate-workshop-account.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { QueryParamsUtil } from '../../../shared/utils/query-params/query-params.util';
import { DictionaryRoleDto } from '../../dtos/dictionary-role/dictionary-role.dto';
import { DictionariesRolesDataPageDto } from '../../dtos/dictionaries-roles-data-page/dictionaries-roles-data-page.dto';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService {
  constructor(private httpClient: HttpClient) {}

  public getCurrentUser(): Observable<CurrentUserDto> {
    return this.httpClient
      .get<ApiResponseDto<CurrentUserDto>>(ApiUrlUtil.getApiUrl('security/me'))
      .pipe(map((response: ApiResponseDto<CurrentUserDto>) => response.data));
  }

  public activateWorkshop(user: ActivateWorkshopAccountDto): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl('user/users/account'), user);
  }

  public deleteCurrentUserProfile(): Observable<void> {
    return this.httpClient.delete<void>(ApiUrlUtil.getApiUrl('user/users'));
  }

  public getDictionaryRoleList(dictionariesRolesDataPage: DictionariesRolesDataPageDto): Observable<ListDto<DictionaryRoleDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<DictionariesRolesDataPageDto>(dictionariesRolesDataPage);

    return this.httpClient
      .get<ApiResponseDto<ListDto<DictionaryRoleDto>>>(ApiUrlUtil.getApiUrl('user/dictionaries/roles'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<DictionaryRoleDto>>) => response.data));
  }
}
