import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ChipModel } from '../../models/chip/chip.model';
import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';

@Component({
  selector: 'app-chips[chips]',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsComponent extends SubscriptionStoreComponent {
  @Input() public chips!: Array<ChipModel>;

  constructor() {
    super();
  }
}
