export enum SettingsOptionKeysEnum {
  colorSystems = 'color_systems',
  defaultColorSystem = 'default_color_system',
  colorboxes = 'colorboxes',
  defaultColorbox = 'default_colorbox',
  roundnessAccuracy = 'roundness_accuracy',
  difficultToWeightThreshold = 'difficult_to_weight_threshold',
  pouringErrorThreshold = 'pouring_error_threshold',
  currencyCode = 'currency_code',
  dateTimeFormat = 'date_time_format',
  show2kColorbox = 'show_2k_colorbox',
}
