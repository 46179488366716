<div class="carousel">
  <div class="header">
    <span class="title title-5">{{ title | translate }}</span>

    <div class="carousel-buttons" *ngIf="!isAllItemsVisible">
      <button class="button-icon" [disabled]="isCarouselAtBeginning" (click)="prev()">
        <span class="icon chevron-left-gray-24x24-icon"></span>
      </button>

      <button class="button-icon" [disabled]="isCarouselAtEnd" (click)="next()">
        <span class="icon chevron-right-gray-24x24-icon"></span>
      </button>
    </div>
  </div>

  <div class="carousel-container" #carouselContainer>
    <div class="carousel-items" #carouselItemsContainer>
      <div class="item" *ngFor="let item of items" #carouselItem>
        <ng-container *ngTemplateOutlet="itemTemplate; context: getContext(item)"></ng-container>
      </div>
    </div>
  </div>
</div>
